import { flow, Instance, types } from 'mobx-state-tree';
import createApiPathModel, {
  defaultModelState,
} from './models/createApiPathModel';
import {
  MOBILE_ACTIONS,
  STORAGE_TYPE,
  UNIT_TYPE,
} from '~/components/Blocks/Templates/Pab2c/Mobile/types';
import { getMobilePhones, getMobileStorageBalances } from '~/api/apiPab2c';
import { compareAsc } from 'date-fns';
import { toJS } from 'mobx';

const RequestsStateModel = types.model('State', {
  getMobileStorageBalances: createApiPathModel(
    'GET /Mobile/MobileBalance/GetContractMobileBalances',
  ),
  getMobilePhones: createApiPathModel(
    'GET /Mobile/MobilePhoneNumber/GetContractResourceMobiles',
  ),
});

const MobileStorageModel = types.model({
  /** Тип накопителя */
  mobileBalanceType: types.enumeration(Object.values(STORAGE_TYPE)),
  /** Единица измерения */
  unit: types.enumeration(Object.values(UNIT_TYPE)),
  /** Ежедневное начисление */
  accrualQuantity: types.maybeNull(types.number),
  /** Лимит начисления */
  maxQuantity: types.maybeNull(types.number),
  /** Стартовое значение накопителя */
  startQuantity: types.maybeNull(types.number),
  /** Текущее значение накопителя */
  currentQuantity: types.maybeNull(types.number),
  // Флаг переполненности накопителя.
  isOverflow: types.boolean,
  // Наименование последнего продукта, у которого накопитель был больше текущего.
  tariffNameOverflowStarted: types.maybeNull(types.string),
});

const MobileSubscriptionModel = types.model({
  /** Тип накопителя */
  mobileBalanceType: types.enumeration(Object.values(STORAGE_TYPE)),
  /** Единица измерения */
  unit: types.enumeration(Object.values(UNIT_TYPE)),
  fromDt: types.string,
  trimDt: types.maybeNull(types.string),
  /** Текущий баланс пакета */
  currentQuantity: types.maybeNull(types.number),
  /** Стартовый баланс пакета */
  startQuantity: types.maybeNull(types.number),
});

export const ContractResourceMobilesModel = types.model({
  simId: types.maybeNull(types.number),
  iccid: types.maybeNull(types.string),
  simCardId: types.maybeNull(types.number),
  msisdn: types.maybeNull(types.string),
  availableMobileActions: types.array(
    types.enumeration(Object.values(MOBILE_ACTIONS)),
  ),
  isActive: types.boolean,
  subscriberName: types.maybeNull(types.string),
});

const MobilePhoneInfoModel = types.model({
  simCardReplacePrice: types.number,
  simCardCourierDeliveryPrice: types.maybeNull(types.number),
  contractResourceMobiles: types.maybeNull(
    types.array(ContractResourceMobilesModel),
  ),
});

const MobileBalancesModel = types.model({
  storageMobileBalances: types.array(MobileStorageModel),
  itemSubscriptionMobileBalances: types.array(MobileSubscriptionModel),
});

export const Pab2cMobileStore = types
  .model('Pab2cMobileStore', {
    requestsState: RequestsStateModel,
    mobileBalances: types.maybeNull(MobileBalancesModel),
    /** Подключенные номера */
    mobilePhonesInfo: types.maybeNull(MobilePhoneInfoModel),
  })
  .views((self) => ({
    get mobilePhonesInfoData() {
      return toJS(self.mobilePhonesInfo);
    },
    get simCardReplacePrice() {
      return self.mobilePhonesInfo?.simCardReplacePrice ?? 0;
    },
    /** Накопители */
    get storages() {
      return self.mobileBalances?.storageMobileBalances
        ? toJS(self.mobileBalances?.storageMobileBalances)
        : [];
    },
    /** Подключенные пакеты */
    get subscriptions() {
      return self.mobileBalances?.itemSubscriptionMobileBalances
        ? toJS(self.mobileBalances?.itemSubscriptionMobileBalances)
        : [];
    },
    get mobilePhones() {
      return toJS(self.mobilePhonesInfo?.contractResourceMobiles);
    },
    get isMobileInfoFetched() {
      return (
        self.requestsState.getMobileStorageBalances.isFetched &&
        self.requestsState.getMobilePhones.isFetched
      );
    },
    get isLoading() {
      return (
        self.requestsState.getMobileStorageBalances.isLoading ||
        self.requestsState.getMobilePhones.isLoading
      );
    },
  }))
  .views((self) => ({
    get numberOfPhones() {
      return self?.mobilePhones?.filter((item) => item?.msisdn)?.length;
    },
    get isConvergentProduct() {
      return self.storages.length !== 0;
    },
    get subscriptionsMinutes() {
      const subs = self.subscriptions.filter(
        (sub) => sub.mobileBalanceType === STORAGE_TYPE.CALL,
      );
      if (subs.length > 1)
        return subs.sort((a, b) =>
          compareAsc(new Date(b.fromDt), new Date(a.fromDt)),
        );
      return subs;
    },
    get subscriptionsGigabites() {
      const subs = self.subscriptions.filter(
        (sub) => sub.mobileBalanceType === STORAGE_TYPE.INTERNET,
      );
      if (subs.length > 1)
        return subs.sort((a, b) =>
          compareAsc(new Date(b.fromDt), new Date(a.fromDt)),
        );
      return subs;
    },
    getStorageByType(type: STORAGE_TYPE) {
      return self.storages.find((store) => store.mobileBalanceType === type);
    },
  }))
  .views((self) => ({
    getUnspentUnits(type: STORAGE_TYPE) {
      const subscriptions =
        type === STORAGE_TYPE.INTERNET
          ? self.subscriptionsGigabites
          : self.subscriptionsMinutes;
      return subscriptions.reduce((acc, sub) => {
        const sum = acc + sub.currentQuantity;
        return sum;
      }, 0);
    },
    /** Получаем максимальное значение шкалы в пакетах */
    getMaxUnitsSub(type: STORAGE_TYPE) {
      const subscriptions =
        type === STORAGE_TYPE.INTERNET
          ? self.subscriptionsGigabites
          : self.subscriptionsMinutes;
      return subscriptions.reduce((acc, sub) => {
        const sum = acc + sub.startQuantity;
        return sum;
      }, 0);
    },
    /** Получаем максимальное значение шкалы в накопителе */
    getMaxUnitsStorage(type: STORAGE_TYPE) {
      if (type === STORAGE_TYPE.SMS)
        return self.storages.find(
          (store) => store.mobileBalanceType === STORAGE_TYPE.SMS,
        )?.maxQuantity;

      const subscriptions =
        type === STORAGE_TYPE.INTERNET
          ? self.subscriptionsGigabites
          : self.subscriptionsMinutes;

      const initialValue =
        self.storages.find((store) => store.mobileBalanceType === type)
          ?.maxQuantity || 0;

      return subscriptions.reduce((acc, sub) => {
        const sum = acc + sub.currentQuantity;
        return sum;
      }, initialValue);
    },
    getMobilePhone(msisdn: string) {
      return self.mobilePhones?.find((item) => item.msisdn === msisdn);
    },
    getMobilePhoneById(simId: number) {
      return self.mobilePhones?.find((item) => item.simId === simId);
    },
  }))
  .actions((self) => ({
    /** Получает информацию о накопителях и подключенных пакетах */
    getMobileStorageBalances: flow(function* () {
      self.requestsState.getMobileStorageBalances.reset();
      self.requestsState.getMobileStorageBalances.setLoading();
      try {
        const res = yield getMobileStorageBalances();
        self.mobileBalances = res;
        self.requestsState.getMobileStorageBalances.setSuccess();
      } catch (e) {
        console.error('getMobileStorageBalances', e);
        self.requestsState.getMobileStorageBalances.setFail();
      }
    }),
    /** Получает информацию о подключенных мобильных номерах */
    getMobilePhones: flow(function* () {
      self.requestsState.getMobilePhones.reset();
      self.requestsState.getMobilePhones.setLoading();
      try {
        const res = yield getMobilePhones();
        self.mobilePhonesInfo = res;
        self.requestsState.getMobilePhones.setSuccess();
      } catch (e) {
        console.error('getMobilePhones', e);
        self.requestsState.getMobilePhones.setFail();
      }
    }),
    setNewPhoneName: (msisdn: string, name: string) => {
      self.mobilePhonesInfo.contractResourceMobiles.find(
        (info) => info.msisdn === msisdn,
      ).subscriberName = name;
    },
    resetMobileStorage: () => {
      self.requestsState.getMobileStorageBalances.reset();
      self.mobileBalances = null;
      self.mobilePhonesInfo = null;
    },
  }))
  .actions((self) => ({
    getMobileInfo: flow(function* () {
      yield Promise.allSettled([
        self.getMobileStorageBalances(),
        self.getMobilePhones(),
      ]);
    }),
  }));

export type IPab2cMobileStore = Instance<typeof Pab2cMobileStore>;

export const Pab2cMobileStoreInstance = Pab2cMobileStore.create({
  requestsState: {
    getMobileStorageBalances: defaultModelState,
    getMobilePhones: defaultModelState,
  },
  mobileBalances: null,
  mobilePhonesInfo: null,
});
