import fetch from 'isomorphic-unfetch';
import sentryWrapper from '~/api/utils/SentryWrapper';
import { baseUrl, getHeadersWithAuth, reqData } from '~/api/api';

/** типы */
import {
  LeasePayAgreementResult,
  Pab2cDevice,
  DevicePurchaseCheckData,
} from '~/components/Blocks/Templates/Pab2c/Devices/interfaces';
import {
  BalanceFields,
  ProductHistoryData,
  ProlongationProps,
  ResourceNetProps,
  SummaryDataProps,
  SuspendConditionProps,
  WeburgMeContractInfoProps,
} from '~/components/Blocks/Templates/Pab2c/Contract/types';
import { PostResponse } from '~/interfaces/ApiInterface';
import {
  DEMAND_CAUSE,
  OPERATING_STATE,
  WORKFLOW_IDS,
} from '~/constants/common';
import {
  ChannelOrderListProps,
  ChannelPackListProps,
  SubjectWithChannels,
  TransformerProps,
  TrialInfoProps,
  ViewControl,
  WinkInfoResult,
} from '~/components/Blocks/Templates/Pab2c/MyTV/interfaces';
import { AllowedTariffProps } from '~/components/Blocks/Templates/ProductSwitcher/interfaces';
import {
  Contacts,
  MutePeriod,
  Subscriptions,
} from '~/components/Blocks/Shared/ContactsAndNotifications/interfaces';
import {
  ChatleHistoryProps,
  ExpandAdvantageData,
  MoneyHistoryProps,
  TransformerHistory,
} from '~/components/Blocks/Templates/Pab2c/Contract/FinancialCalculations/interfaces';
import { TransformLimitExceededProps } from '~/components/Blocks/Templates/Pab2c/Contract/TransformLimitExceeded/interaces';
import {
  AppealData,
  CommentData,
} from '~/components/Blocks/Templates/Pab2c/Help/interfaces';
import {
  PassiveMessageProps,
  ZombieInfoProps,
} from '~/components/Blocks/Templates/Pab2c/Subscriptions/interfaces';
import { NewContractProps } from '~/components/Blocks/Templates/Pab2c/ContractState/types';
import {
  CodePrice,
  ForwardingRule,
  Phone,
  Price,
  Satellite,
  StatisticProps,
  Agreement,
} from '~/components/Blocks/Templates/Pab2c/Voice/interfaces';
import { StateCondition } from '~/components/Blocks/Templates/Pab2c/Voice/constants';
import { ContactType } from '~/components/Blocks/Templates/Pab2c/Settings/interfaces';
import {
  DELIVERY_TYPE,
  AvailableMobilePhoneNumbers,
  MobilePhoneInfo,
  MobilePhoneNumberCategories,
  MobileStorageBalances,
} from '~/components/Blocks/Templates/Pab2c/Mobile/types';

/** utils */
import responseHandler from '~/api/utils/responseHandler';

/**
 * Возвращает информацию об устройстве.
 * @returns {Promise<Pab2cDevice>}
 */
export const getPab2cDevices = async (): Promise<Pab2cDevice[]> => {
  const data = await fetch(
    `${baseUrl}/Device/ContractDevice/GetContractDevices`,
    {
      ...reqData,
      headers: getHeadersWithAuth(),
    },
  );
  const res = await data.json();
  if (!data.ok) sentryWrapper('getPab2cDevices', data.status, res);

  return res;
};

/**
 * Возвращает информацию о балансе договора.
 * @returns {Promise<BalanceFields>}
 */
export const getBalance = async (): Promise<BalanceFields> => {
  const data = await fetch(`${baseUrl}/Finances/Contract/GetBalance`, {
    ...reqData,
    headers: getHeadersWithAuth(),
  });
  const res = await data.json();
  if (!data.ok) sentryWrapper('getBalance', data.status, res);

  return res;
};

/**
 * Возвращает информацию по факту резервирования чатлов
 * @param amount
 *
 * @returns {Promise<PostResponse>}
 */
export const setReservedChatles = async (
  amount: number,
): Promise<PostResponse> => {
  const data = await fetch(
    `${baseUrl}/Chatle/Chatle/SetReservedChatles?amount=${amount}`,
    {
      ...reqData,
      method: 'POST',
      headers: getHeadersWithAuth(),
    },
  );
  if (!data.ok) sentryWrapper('SetReservedChatles', data.status, null);

  return { status: data.status };
};

/**
 * Метод для выкупа оборудования
 * @param simId
 * @param isLease
 * @param isBindSimWarranty
 */
export const devicePurchase = async (
  simId: number,
  isLease: boolean,
  isBindSimWarranty: boolean,
): Promise<void> => {
  const data = await fetch(
    `${baseUrl}/Device/ContractDevice/DevicePurchase?simId=${simId}&isLease=${isLease}&isBindSimWarranty=${isBindSimWarranty}`,
    {
      ...reqData,
      method: 'POST',
      headers: getHeadersWithAuth(),
    },
  );
  responseHandler(data);
  if (!data.ok) {
    sentryWrapper('DevicePurchase', data.status, data);
  }
};

/**
 * Метод для досрочного погашения задолженности. Если нарушены условия погашения, то возвращает информацию о них.
 * @param simId
 * @param amount
 */
export const leasePay = async (
  simId: number,
  amount: number,
): Promise<void> => {
  const data = await fetch(
    `${baseUrl}/Device/ContractDevice/LeasePay?simId=${simId}&money=${amount}`,
    {
      ...reqData,
      method: 'POST',
      headers: getHeadersWithAuth(),
    },
  );
  responseHandler(data);
  if (data.ok) return;

  const res = await data.json();
  sentryWrapper('LeasePay', data.status, res);
};

/**
 * Проверка возможности выкупа оборудования.
 * @param simId
 * @param isLease
 *
 * @returns {Promise<DevicePurchaseCheckData>}
 */
export const devicePurchaseCheck = async (
  simId: number,
  isLease: boolean,
): Promise<DevicePurchaseCheckData> => {
  const data = await fetch(
    `${baseUrl}/Device/ContractDevice/DevicePurchaseCheck?simId=${simId}&isLease=${isLease}`,
    {
      ...reqData,
      headers: getHeadersWithAuth(),
    },
  );
  const res = data.ok ? await data.json() : data;
  responseHandler(data);
  if (!data.ok) sentryWrapper('DevicePurchaseCheck', data.status, res);

  return res;
};

/**
 * Возвращает флаг возможности погашения рассрочки.
 * @returns {Promise<boolean>}
 */
export const checkLeasePay = async (): Promise<boolean> => {
  const data = await fetch(`${baseUrl}/Device/ContractDevice/CheckLeasePay`, {
    ...reqData,
    headers: getHeadersWithAuth(),
  });

  const res = data.ok ? await data.json() : data;
  responseHandler(data);
  if (!data.ok) sentryWrapper('checkLeasePay', data.status, res);

  return res.isAvailableLeasePay;
};

/**
 * Приостанавливает обслуживание (добровольная блокировка) по договору, начиная с завтрашнего дня.
 * @returns {Promise<void>}
 */
export const suspendService = async (): Promise<void> => {
  const data = await fetch(
    `${baseUrl}/OperatingState/OperatingState/SuspendService`,
    {
      ...reqData,
      method: 'POST',
      headers: getHeadersWithAuth(),
    },
  );
  responseHandler(data);
  if (!data.ok) {
    sentryWrapper('SuspendService', data.status, data);
  }
};

/**
 * Восстанавливает обслуживание (после добровольной блокировки) по договору начиная с сегодня.
 * @returns {Promise<void>}
 */
export const resumeService = async (): Promise<void> => {
  const data = await fetch(
    `${baseUrl}/OperatingState/OperatingState/ResumeService`,
    {
      ...reqData,
      method: 'POST',
      headers: getHeadersWithAuth(),
    },
  );
  responseHandler(data);
  if (!data.ok) {
    const res = data.json();
    sentryWrapper('ResumeService', data.status, res);
  }
};

/**
 * Получает историю продукта клиента
 * @returns {Promise<ProductHistoryData[]>}
 */
export const getTariffHistory = async (
  fromDate: string,
  trimDate: string,
): Promise<ProductHistoryData[]> => {
  const data = await fetch(
    `${baseUrl}/Tax/Tax/GetTariffHistory?fromDate=${fromDate}&trimDate=${trimDate}`,
    {
      ...reqData,
      headers: getHeadersWithAuth(),
    },
  );
  const res = await data.json();
  if (!data.ok) sentryWrapper('getTariffHistory', data.status, res);

  return res;
};

/**
 * Возвращает код серии текущего продукта договора.
 * @returns {Promise<string>}
 */
export const getContractTariff = async (): Promise<{
  seriesId: number;
  tariffId: number;
}> => {
  const data = await fetch(`${baseUrl}/Tax/Tax/GetContractTariff`, {
    ...reqData,
    headers: getHeadersWithAuth(),
  });
  const res = await data.json();
  if (!data.ok) sentryWrapper('getContractTariff', data.status, res);

  return res;
};

export const warrantyAgreementGet = async (simId: number): Promise<string> => {
  const data = await fetch(
    `${baseUrl}/Device/ContractDevice/WarrantyAgreementGet?simId=${simId}`,
    {
      ...reqData,
      headers: getHeadersWithAuth(),
    },
  );

  responseHandler(data);
  const res = data.ok ? await data.json() : data;
  if (!data.ok) sentryWrapper('WarrantyAgreementGet', data.status, res);

  return res.data;
};

export const leasePayAgreementGet = async (
  simId: number,
): Promise<LeasePayAgreementResult> => {
  const data = await fetch(
    `${baseUrl}/Device/ContractDevice/LeasePayAgreementGet?simId=${simId}`,
    {
      ...reqData,
      headers: getHeadersWithAuth(),
    },
  );

  const res = data.json();
  responseHandler(data);
  if (!data.ok) sentryWrapper('LeasePayAgreementGet', data.status, res);

  return res;
};

/**
 * Получает общую информацию о продукте, необходимую в блоке summary по коду серии.
 * @returns {Promise<SummaryDataProps>}
 */
export const getSummary = async (
  seriesCode: string,
): Promise<SummaryDataProps> => {
  const data = await fetch(
    `${baseUrl}/Summary/Summary/GetSummary?seriesCode=${seriesCode}`,
    {
      ...reqData,
      headers: getHeadersWithAuth(),
    },
  );
  const res = await data.json();
  if (!data.ok) sentryWrapper('getSummary', data.status, res);

  return res;
};

/**
 * Получает общую информацию о продуктах по идентификаторам, необходимую в блоках Summary и Product Switcher.
 * @returns {Promise<SummaryDataProps[]>}
 */
export const getSummariesByTariffIds = async (
  tariffIds: number[],
): Promise<SummaryDataProps[]> => {
  const ids = tariffIds.length
    ? tariffIds.map((tariffId) => `tariffIds=${tariffId}`).join('&')
    : [];
  const data = await fetch(
    `${baseUrl}/Summary/Summary/GetSummariesByTariffIds?${ids}`,
    reqData,
  );
  const res = await data.json();
  if (!data.ok) sentryWrapper('getSummariesByTariffIds', data.status, res);

  return res;
};

/**
 * Возвращает состояние договора.
 * @returns {Promise<OPERATING_STATE>}
 */
export const getOperatingState = async (): Promise<OPERATING_STATE> => {
  const data = await fetch(
    `${baseUrl}/OperatingState/OperatingState/GetState`,
    {
      ...reqData,
      headers: getHeadersWithAuth(),
    },
  );
  const res = await data.json();
  if (!data.ok) sentryWrapper('getOperatingState', data.status, res);

  return res?.operatingStateCode;
};

/**
 * Получает информацию о заказе добровольной приостановки в будущем.
 * @returns {Promise<SuspendConditionProps>}
 */
export const getSuspendCondition = async (): Promise<SuspendConditionProps> => {
  const data = await fetch(
    `${baseUrl}/OperatingState/OperatingState/GetSuspendCondition`,
    {
      ...reqData,
      headers: getHeadersWithAuth(),
    },
  );
  const res = await data.json();
  if (!data.ok) sentryWrapper('getSuspendCondition', data.status, res);

  return res;
};

/**
 * Возвращает информацию о договоре клиенте/абоненте
 * @returns {Promise<WeburgMeContractInfoProps>}
 */
export const getWeburgMeContractInfo = async (): Promise<WeburgMeContractInfoProps> => {
  const data = await fetch(`${baseUrl}/Tax/Tax/GetWeburgMeContractInfo`, {
    ...reqData,
    headers: getHeadersWithAuth(),
  });
  const res = await data.json();
  if (!data.ok) sentryWrapper('getWeburgMeContractInfo', data.status, res);

  return res;
};

/**
 * Возвращает список подсетей IPv4 и IPv6, прикреплённых к договору в данный момент
 * @returns {Promise<ResourceNetProps[]>}
 */
export const getResourceNetAll = async (): Promise<ResourceNetProps[]> => {
  const data = await fetch(`${baseUrl}/Net/Net/GetResourceNetAll`, {
    ...reqData,
    headers: getHeadersWithAuth(),
  });
  const res = await data.json();
  if (!data.ok) sentryWrapper('getResourceNetAll', data.status, res);

  return res;
};

export const warrantyBind = async (simId: number): Promise<void> => {
  const data = await fetch(
    `${baseUrl}/Device/ContractDevice/WarrantyBind?simId=${simId}`,
    {
      ...reqData,
      method: 'POST',
      headers: getHeadersWithAuth(),
    },
  );
  responseHandler(data);
  if (!data.ok) {
    sentryWrapper('WarrantyBind', data.status, data);
  }
};

export const warrantyUnbind = async (simId: number): Promise<void> => {
  const data = await fetch(
    `${baseUrl}/Device/ContractDevice/WarrantyUnbind?simId=${simId}`,
    {
      ...reqData,
      method: 'POST',
      headers: getHeadersWithAuth(),
    },
  );
  responseHandler(data);
  if (!data.ok) {
    sentryWrapper('WarrantyUnbind', data.status, data);
  }
};

/**
 * Возвращает пользовательское соглашение.
 * @param simId
 * @param isEnabled
 */
export const getIPv6Agreement = async (
  simId: number,
  isEnabled: boolean,
): Promise<string> => {
  const data = await fetch(
    `${baseUrl}/Net/Net/GetDhcpiPv6FlagAgreement?simId=${simId}&isIpv6DhcpEnabled=${isEnabled}`,
    {
      ...reqData,
      headers: getHeadersWithAuth(),
    },
  );

  const res = data.ok ? await data?.json() : data;
  responseHandler(data);
  if (!data.ok) {
    sentryWrapper('getIPv6Agreement', data.status, res);
  }
  return data.ok ? res?.data : res;
};

/**
 * Изменяет значение флага is_ipv6_dhcp_enabled на ресурсе типа SIM.net.
 *
 * @param simId
 * @param isEnabled
 */
export const setDHCPIPv6Flag = async (
  simId: number,
  isEnabled: boolean,
): Promise<void> => {
  const data = await fetch(
    `${baseUrl}/Net/Net/SetDHCPIPv6Flag?simId=${simId}&isIpv6DhcpEnabled=${isEnabled}`,
    {
      ...reqData,
      method: 'POST',
      headers: getHeadersWithAuth(),
    },
  );
  responseHandler(data);
  if (!data.ok) {
    const res = data.ok ? data.json() : data;
    sentryWrapper('SetDHCPIPv6Flag', data.status, res);
  }
};

/** Заказывает услугу Каникулы */
export const vacationStart = async (): Promise<void> => {
  const data = await fetch(
    `${baseUrl}/OperatingState/OperatingState/VacationStart`,
    {
      ...reqData,
      method: 'POST',
      headers: getHeadersWithAuth(),
    },
  );
  responseHandler(data);
  if (!data.ok) {
    const res = data.json();
    sentryWrapper('vacationStart', data.status, res);
  }
};

/** Отказывается от услуги Каникулы */
export const vacationStop = async (): Promise<void> => {
  const data = await fetch(
    `${baseUrl}/OperatingState/OperatingState/VacationStop`,
    {
      ...reqData,
      method: 'POST',
      headers: getHeadersWithAuth(),
    },
  );
  responseHandler(data);
  if (!data.ok) {
    const res = data.json();
    sentryWrapper('vacationStop', data.status, res);
  }
};

/** Отменяет заказ услуги Каникулы */
export const vacationCancel = async (): Promise<void> => {
  const data = await fetch(
    `${baseUrl}/OperatingState/OperatingState/VacationCancel`,
    {
      ...reqData,
      method: 'POST',
      headers: getHeadersWithAuth(),
    },
  );
  responseHandler(data);
  if (!data.ok) {
    const res = data.json();
    sentryWrapper('vacationCancel', data.status, res);
  }
};

/**
 * Получает текст соглашения на отключение услуги Каникулы
 */
export const getVacationDisableAgreement = async (): Promise<{
  status: { code: string };
  data: string;
}> => {
  const data = await fetch(
    `${baseUrl}/OperatingState/OperatingState/GetVacationDisableAgreement`,
    {
      ...reqData,
      headers: getHeadersWithAuth(),
    },
  );

  const res = data.json();
  responseHandler(data);
  if (!data.ok) sentryWrapper('getVacationDisableAgreement', data.status, res);

  return res;
};

/**
 * Получает текст соглашения на подключение или отключение услуги Каникулы
 */
export const getVacationAgreement = async (): Promise<string> => {
  const data = await fetch(
    `${baseUrl}/OperatingState/OperatingState/GetVacationAgreement`,
    {
      ...reqData,
      headers: getHeadersWithAuth(),
    },
  );
  const res = await data.json();
  responseHandler(data);
  if (!data.ok) sentryWrapper('getVacationEnableAgreement', data.status, res);
  return res.data;
};

/**
 * Возвращает список всех доступных пакетов
 */
export const getITvChannelPackList = async (): Promise<
  ChannelPackListProps[]
> => {
  const data = await fetch(
    `${baseUrl}/Itv/ContractItv/ITvChannelPackListIncludeFree`,
    {
      ...reqData,
      headers: getHeadersWithAuth(),
    },
  );
  const res = await data.json();
  responseHandler(data);
  if (!data.ok) sentryWrapper('getITvChannelPackList', data.status, res);
  return res;
};

/*
 * Возвращает доступные пакеты для серии продукта по её коду
 * @returns {Promise<ChannelPackListProps[]>}
 */
export const getITVChannelPackListBySeriesCode = async (
  seriesCode?: string,
): Promise<ChannelPackListProps[]> => {
  const data = await fetch(
    `${baseUrl}/Itv/ContractItv/ITVChannelPackListBySeriesCode${
      seriesCode ? `?seriesCode=${seriesCode}` : ''
    }`,
    {
      ...reqData,
    },
  );
  responseHandler(data);
  const res = await data.json();
  if (!data.ok)
    sentryWrapper('getITVChannelPackListBySeriesCode', data.status, res);
  return res;
};

/**
 * Возвращает список подключённых пакетов телеканалов.
 */
export const getITvChannelOrderList = async (): Promise<
  ChannelOrderListProps[]
> => {
  const data = await fetch(`${baseUrl}/Itv/ContractItv/ITvChannelOrderList`, {
    ...reqData,
    headers: getHeadersWithAuth(),
  });

  const res = await data.json();
  responseHandler(data);
  if (!data.ok) sentryWrapper('getITvChannelOrderList', data.status, res);

  return res;
};

/**
 * Возвращает текст соглашения об услугах при подключении пакета телеканалов
 */
export const getITvChannelPackBindAgreement = async (
  channelPackId: number,
): Promise<string> => {
  const data = await fetch(
    `${baseUrl}/Itv/ContractItv/ITvChannelPackBindAgreement?channelPackId=${channelPackId}`,
    {
      ...reqData,
      headers: getHeadersWithAuth(),
    },
  );

  const res = await data.json();
  responseHandler(data);
  if (!data.ok)
    sentryWrapper('getITvChannelPackBindAgreement', data.status, res);

  return res.data;
};

/**
 * Возвращает текст соглашения об услугах при отключении пакета телеканалов
 */
export const getITvChannelPackUnbindAgreement = async (
  channelPackId: number,
): Promise<string> => {
  const data = await fetch(
    `${baseUrl}/Itv/ContractItv/ITvChannelPackUnbindAgreement?channelPackId=${channelPackId}`,
    {
      ...reqData,
      headers: getHeadersWithAuth(),
    },
  );

  const res = await data.json();
  responseHandler(data);
  if (!data.ok)
    sentryWrapper('getITvChannelPackUnbindAgreement', data.status, res);

  return res.data;
};

/**
 * Проверяет предусловия подключения пакета телеканалов к договору
 */
export const checkITvChannelPackBindCondition = async (
  channelPackId: number,
): Promise<void> => {
  const data = await fetch(
    `${baseUrl}/Itv/ContractItv/ITvChannelPackBindConditionCheck?channelPackId=${channelPackId}`,
    {
      ...reqData,
      headers: getHeadersWithAuth(),
    },
  );
  responseHandler(data);
  if (!data.ok) {
    const res = data.json();
    sentryWrapper('checkITvChannelPackBindCondition', data.status, res);
  }
};

/**
 * Проверяет предусловия открепления пакета телеканалов от договора (отказ от телеканала)
 */
export const checkITvChannelPackUnbindCondition = async (
  channelPackId: number,
): Promise<void> => {
  const data = await fetch(
    `${baseUrl}/Itv/ContractItv/ITvChannelPackUnbindConditionCheck?channelPackId=${channelPackId}`,
    {
      ...reqData,
      headers: getHeadersWithAuth(),
    },
  );
  responseHandler(data);
  if (!data.ok) {
    const res = data.json();
    sentryWrapper('checkITvChannelPackUnbindCondition', data.status, res);
  }
};

/**
 * Прикрепляет пакет телеканалов к договору
 */
export const bindITvChannelPack = async (
  channelPackId: number,
): Promise<void> => {
  const data = await fetch(
    `${baseUrl}/Itv/ContractItv/ITvChannelPackBind?channelPackId=${channelPackId}`,
    {
      ...reqData,
      method: 'POST',
      headers: getHeadersWithAuth(),
    },
  );
  if (!data.ok) {
    const res = await data.json();
    sentryWrapper('bindITvChannelPack', data.status, res);
  }
};

/**
 * Открепляет пакет телеканалов от договора (отказ от телеканала)
 */
export const unbindITvChannelPack = async (
  channelPackId: number,
): Promise<void> => {
  const data = await fetch(
    `${baseUrl}/Itv/ContractItv/ITvChannelPackUnbind?channelPackId=${channelPackId}`,
    {
      ...reqData,
      method: 'POST',
      headers: getHeadersWithAuth(),
    },
  );
  if (!data.ok) {
    const res = await data.json();
    sentryWrapper('unbindITvChannelPack', data.status, res);
  }
};

/**
 * Получает информацию о состоянии услуги "Управление просмотром"
 */
export const getITvTimeshiftInfo = async (): Promise<ViewControl> => {
  const data = await fetch(`${baseUrl}/Itv/ContractItv/ITvTimeshiftInfoGet`, {
    ...reqData,
    headers: getHeadersWithAuth(),
  });
  const res = await data.json();

  responseHandler(data);
  if (!data.ok) sentryWrapper('getITvTimeshiftInfo', data.status, res);
  return res;
};

/**
 * Получает соглашение для прикрепления услуги "Управление просмотром"
 */
export const getITvTimeshiftBindAgreement = async (): Promise<string> => {
  const data = await fetch(
    `${baseUrl}/Itv/ContractItv/ITvTimeshiftBindAgreement`,
    {
      ...reqData,
      headers: getHeadersWithAuth(),
    },
  );
  const res = await data.json();
  responseHandler(data);
  if (!data.ok) sentryWrapper('getITvTimeshiftBindAgreement', data.status, res);
  return res.data;
};

/**
 * Получает соглашение для открепления услуги "Управление просмотром"
 */
export const getITvTimeshiftUnbindAgreement = async (): Promise<string> => {
  const data = await fetch(
    `${baseUrl}/Itv/ContractItv/ITvTimeshiftUnbindAgreement`,
    {
      ...reqData,
      headers: getHeadersWithAuth(),
    },
  );
  const res = await data.json();
  responseHandler(data);
  if (!data.ok)
    sentryWrapper('getITvTimeshiftUnbindAgreement', data.status, res);
  return res.data;
};

/**
 * Подключает услугу "Управление просмотром"
 */
export const bindITvTimeshift = async (): Promise<void> => {
  const data = await fetch(`${baseUrl}/Itv/ContractItv/ITvTimeshiftBind`, {
    ...reqData,
    method: 'POST',
    headers: getHeadersWithAuth(),
  });
  if (!data.ok) {
    const res = await data.json();
    sentryWrapper('bindITvTimeshift', data.status, res);
  }
};

/**
 * Отключает услугу "Управление просмотром"
 */
export const unbindITvTimeshift = async (): Promise<void> => {
  const data = await fetch(`${baseUrl}/Itv/ContractItv/ITvTimeshiftUnbind`, {
    ...reqData,
    method: 'POST',
    headers: getHeadersWithAuth(),
  });
  if (!data.ok) {
    const res = await data.json();
    sentryWrapper('unbindITvTimeshift', data.status, res);
  }
};

/**
 * Проверяет, можно ли для договора подключить услугу "Управление просмотром"
 */
export const checkITvTimeshiftBindCondition = async (): Promise<void> => {
  const data = await fetch(
    `${baseUrl}/Itv/ContractItv/ITvTimeshiftBindConditionCheck`,
    {
      ...reqData,
      method: 'POST',
      headers: getHeadersWithAuth(),
    },
  );
  if (!data.ok) {
    const res = await data.json();
    sentryWrapper('checkITvTimeshiftBindCondition', data.status, res);
  }
};

/**
 * Проверяет, можно ли для договора отключить услугу "Управление просмотром"
 */
export const checkITvTimeshiftUnbindCondition = async (): Promise<void> => {
  const data = await fetch(
    `${baseUrl}/Itv/ContractItv/ITvTimeshiftUnbindConditionCheck`,
    {
      ...reqData,
      method: 'POST',
      headers: getHeadersWithAuth(),
    },
  );
  if (!data.ok) {
    const res = await data.json();
    sentryWrapper('checkITvTimeshiftUnbindCondition', data.status, res);
  }
};

/**
 * Возвращает информацию о текущем состоянии Трансформера на договоре
 */
export const getTransformerStatus = async (): Promise<TransformerProps> => {
  const data = await fetch(`${baseUrl}/Itv/Transformer/TransformerStatusGet`, {
    ...reqData,
    headers: getHeadersWithAuth(),
  });
  const res = data.status === 200 ? data.json() : null;
  responseHandler(data);
  if (!data.ok) sentryWrapper('getTransformerStatus', data.status, res);
  return res;
};

/**
 * Прикрепляет пакеты для договора в контексте трансформера
 */
export const transformerApply = async (
  channelPackIds: number[],
): Promise<void> => {
  const ids = channelPackIds.length
    ? channelPackIds.map((item) => `channelPackIds=${item}`).join('&')
    : [];
  const data = await fetch(
    `${baseUrl}/Itv/Transformer/TransformerApply?${ids}`,
    {
      ...reqData,
      method: 'POST',
      headers: getHeadersWithAuth(),
    },
  );
  responseHandler(data);
  if (!data.ok) {
    const res = await data.json();
    sentryWrapper('transformerApply', data.status, res);
  }
};

/**
 * Возвращает текст пользовательского соглашения
 */
export const getTransformerAgreement = async (
  channelPackIds: number[],
): Promise<string> => {
  const ids = channelPackIds.map((item) => `channelPackIds=${item}`).join('&');
  const data = await fetch(
    `${baseUrl}/Itv/Transformer/TransformerAgreementGet?${ids}`,
    {
      ...reqData,
      method: 'POST',
      headers: getHeadersWithAuth(),
    },
  );
  const res = await data.json();
  responseHandler(data);
  if (!data.ok) sentryWrapper('getTransformerAgreement', data.status, res);
  return res?.message;
};

/**
 * Возвращает информацию о подключении WINK
 */
export const getWinkInfo = async (): Promise<WinkInfoResult> => {
  const data = await fetch(`${baseUrl}/Wink/Wink/GetWinkInfo`, {
    ...reqData,
    method: 'GET',
    headers: getHeadersWithAuth(),
  });

  const res = await data.json();
  responseHandler(data);
  if (!data.ok) {
    sentryWrapper('getWinkInfo', data.status, res);
  }

  return res;
};

/**
 * Подключает услугу WINK
 */
export const winkAccountCreate = async (phone?: string): Promise<string> => {
  const data = await fetch(
    phone
      ? `${baseUrl}/Wink/Wink/winkAccountCreate?phone=${phone.replace(
          /\D/g,
          '',
        )}`
      : `${baseUrl}/Wink/Wink/winkAccountCreate}`,
    {
      ...reqData,
      method: 'POST',
      headers: getHeadersWithAuth(),
    },
  );
  const res = await data.json();
  responseHandler(data);
  if (!data.ok) sentryWrapper('winkAccountCreate', data.status, res);

  return res.ottCode;
};

/**
 * Асинхронно получает список тематик ТВ-каналов по идентификатору продукта.
 */
export const getChannelsByTariffId = async (
  tariffId: number,
): Promise<SubjectWithChannels[]> => {
  const data = await fetch(
    `${baseUrl}/Itv/GetChannelsByTariffId?tariffId=${tariffId}`,
    {
      ...reqData,
      method: 'GET',
      headers: getHeadersWithAuth(),
    },
  );

  const res = await data.json();
  responseHandler(data);
  if (!data.ok) {
    sentryWrapper('getChannelsByTariffId', data.status, res);
  }

  return res;
};

/**
 * Возвращает список доступных для данного договора продуктов и условия переходов на них
 */
export const getTariffAllowed = async (): Promise<AllowedTariffProps[]> => {
  const data = await fetch(`${baseUrl}/Tax/Tax/GetTariffAllowed`, {
    ...reqData,
    method: 'GET',
    headers: getHeadersWithAuth(),
  });

  const res = await data.json();
  responseHandler(data);
  if (!data.ok) {
    sentryWrapper('getTariffAllowed', data.status, res);
  }

  return res;
};

/**
 * Проверяет возможность перехода на другой продукт
 */
export const tariffCheckSwitchAgreement = async (
  tariffIdTo: number,
): Promise<string> => {
  const data = await fetch(
    `${baseUrl}/Tax/Tax/GetTariffSwitchAgreement?tariffIdTo=${tariffIdTo}`,
    {
      ...reqData,
      headers: getHeadersWithAuth(),
    },
  );
  const res = await data.json();
  responseHandler(data);
  if (!data.ok) sentryWrapper('tariffCheckSwitchAgreement', data.status, res);

  return res?.message;
};

/**
 * Устанавливает тариф
 */
export const changeTariff = async (tariffId: number): Promise<void> => {
  const data = await fetch(
    `${baseUrl}/Tax/Tax/SetTariff?tariffId=${tariffId}`,
    {
      ...reqData,
      method: 'POST',
      headers: getHeadersWithAuth(),
    },
  );
  if (!data.ok) {
    const res = await data.json();
    responseHandler(data);
    sentryWrapper('changeTariff', data.status, res);
    return res;
  }
  return null;
};

/**
 * Возвращает информацию о состоянии услуги Пролонгация на договоре
 */
export const getProlongationInfo = async (): Promise<ProlongationProps> => {
  const data = await fetch(`${baseUrl}/Tax/Tax/GetProlongationInfo`, {
    ...reqData,
    headers: getHeadersWithAuth(),
  });
  const res = await data.json();
  responseHandler(data);
  if (!data.ok) sentryWrapper('getProlongationInfo', data.status, res);
  return res;
};

/**
 * Возвращает соглашение для услуги Пролонгация на договоре
 */
export const getProlongationAgreement = async (): Promise<string> => {
  const data = await fetch(`${baseUrl}/Tax/Tax/GetProlongationAgreement`, {
    ...reqData,
    headers: getHeadersWithAuth(),
  });
  const res = await data.json();
  responseHandler(data);
  if (!data.ok) sentryWrapper('getProlongationAgreement', data.status, res);
  return res?.data;
};

/**
 * Производит пролонгацию на договоре
 */
export const prolongate = async (): Promise<{ statusCode: string }> => {
  const data = await fetch(`${baseUrl}/Tax/Tax/Prolongate`, {
    ...reqData,
    method: 'POST',
    headers: getHeadersWithAuth(),
  });
  const res = await data.json();
  responseHandler(data);
  if (!data.ok) sentryWrapper('prolongate', data.status, res);

  return res;
};

/*
 * Получает информацию о статусе триального периода.
 * @returns {Promise<boolean>}
 */
export const getITvTrialPeriodCondition = async (): Promise<TrialInfoProps> => {
  const data = await fetch(
    `${baseUrl}/Itv/ContractItv/ITvTrialPeriodCondition`,
    {
      ...reqData,
      headers: getHeadersWithAuth(),
    },
  );
  responseHandler(data);
  const res = await data.json();
  if (!data.ok) sentryWrapper('getITvTrialPeriodCondition', data.status, res);
  return res;
};

/*
 * Получает текст соглашения возврата оборудования.
 */
export const getRegressDeviceAgreement = async (
  deviceTypeCode: string,
): Promise<string> => {
  const data = await fetch(
    `${baseUrl}/Device/ContractDevice/GetRegressDeviceAgreement?deviceTypeCode=${deviceTypeCode}`,
    {
      ...reqData,
      headers: getHeadersWithAuth(),
    },
  );
  responseHandler(data);
  const res = data.ok ? await data?.json() : data;
  if (!data.ok) sentryWrapper('getRegressDeviceAgreement', data.status, res);

  return res.data;
};

/*
 * Получает текст соглашения приостановки обслуживания.
 * @returns {Promise<boolean>}
 */
export const getSuspendingAgreement = async (): Promise<string> => {
  const data = await fetch(
    `${baseUrl}/OperatingState/OperatingState/GetSuspendingAgreement`,
    {
      ...reqData,
      headers: getHeadersWithAuth(),
    },
  );
  const res = data.ok ? await data?.json() : data;
  responseHandler(data);
  if (!data.ok) sentryWrapper('getSuspendingAgreement', data.status, res);
  return data.ok ? res?.data : res;
};

/*
 * Генерирует пароль.
 * @returns {Promise<boolean>}
 */
export const generatePassword = async (): Promise<string> => {
  const data = await fetch(
    `${baseUrl}/Security/PasswordRecovery/GeneratePassword?length=8`,
    {
      ...reqData,
      headers: getHeadersWithAuth(),
    },
  );
  responseHandler(data);
  const res = await data.json();
  if (!data.ok) sentryWrapper('generatePassword', data.status, res);

  return res.password;
};

/**
 * Устанавливает новый пароль.
 */
export const definePassword = async (
  password: string,
  token: string,
): Promise<{ status: number }> => {
  const data = await fetch(
    `${baseUrl}/Security/PasswordRecovery/SetPassword?password=${password}`,
    {
      ...reqData,
      method: 'POST',
      // Временный token, не добавлять в cookies
      headers: { Authorization: `Bearer ${token}` },
    },
  );
  if (!data.ok) sentryWrapper('definePassword', data.status, null);

  return { status: data.status };
};

/**
 * Изменяет старый пароль на введённый новый.
 */
export const changePassword = async (
  oldPassword: string,
  newPassword: string,
): Promise<void> => {
  const data = await fetch(
    `${baseUrl}/Security/PasswordRecovery/ChangePassword?oldPassword=${oldPassword}&newPassword=${newPassword}`,
    {
      ...reqData,
      method: 'POST',
      headers: getHeadersWithAuth(),
    },
  );
  responseHandler(data);
  if (!data.ok) {
    sentryWrapper('changePassword', data.status, data);
  }
};

/**
 * Возвращает список контактов контрагента по идентификатору договора
 */
export const getContacts = async (): Promise<Contacts[]> => {
  const data = await fetch(`${baseUrl}/Contacts/Contact/GetContacts`, {
    ...reqData,
    headers: getHeadersWithAuth(),
  });
  responseHandler(data);
  const res = await data.json();
  if (!data.ok) sentryWrapper('getContacts', data.status, res);
  return res;
};

/**
 * Добавляет новый контакт контрагенту по идентификатору договора
 * @param contactValueClassId - Идентификатор класса значения контакта.
 * @param value - Значение контакта.
 * @param description - Описание контакта.
 */
export const addContact = async (
  contactValueClassId: number,
  value: string,
): Promise<Contacts> => {
  const data = await fetch(
    `${baseUrl}/Contacts/Contact/AddContact?contactValueClassId=${contactValueClassId}&value=${value}`,
    {
      ...reqData,
      method: 'POST',
      headers: getHeadersWithAuth(),
    },
  );
  responseHandler(data);
  const res = data.ok ? await data.json() : data;
  if (!data.ok) sentryWrapper('AddContact', data.status, res);
  return res;
};

/*
 * Отправляет текстовое сообщение с временным кодом на сотовый
 */
export const sendCodeBySMS = async (
  contractName: string,
  phoneNumber: string,
): Promise<void> => {
  const data = await fetch(
    `${baseUrl}/Security/PasswordRecovery/SendCodeBySMS?contractName=${contractName}&phoneNumber=${phoneNumber}`,
    {
      ...reqData,
      method: 'POST',
    },
  );
  responseHandler(data);
  if (!data.ok) {
    const res = await data.json();
    sentryWrapper('sendCodeBySMS', data.status, res);
  }
};

/**
 * Удаляет контакт контрагента по идентификатору договора
 * @param contactId - Идентификатор контакта.
 */
export const deleteContact = async (contactId: number): Promise<void> => {
  const data = await fetch(
    `${baseUrl}/Contacts/Contact/DeleteContact?contactId=${contactId}`,
    {
      ...reqData,
      method: 'POST',
      headers: getHeadersWithAuth(),
    },
  );
  responseHandler(data);
  if (!data.ok) {
    sentryWrapper('DeleteContact', data.status, data);
  }
};

/**
 * Отправляет сообщение с токеном подтверждения контакта на указанный контакт.
 * @param contactId - Идентификатор контакта.
 * @param verificationToken - Токен для подтверждения контакта.
 * @param clientName - Название контрагента.
 * @param verificationLink - Верификационная ссылка.
 * @param mailTitle -Заголовок письма.
 */
export const sendVerificationMessage = async (
  contactId: number,
): Promise<void> => {
  const data = await fetch(
    `${baseUrl}/Contacts/Contact/SendVerificationMessage?contactId=${contactId}`,
    {
      ...reqData,
      method: 'POST',
      headers: getHeadersWithAuth(),
    },
  );

  responseHandler(data);

  if (!data.ok) {
    const res = await data.json();
    sentryWrapper('SendVerificationMessage', data.status, res);
  }
};

/*
 * Отправляет запрос на диктовку временного кода по сотовому
 */
export const sendCodeByCall = async (
  contractName: string,
  phoneNumber: string,
): Promise<void> => {
  const data = await fetch(
    `${baseUrl}/Security/PasswordRecovery/SendCodeByCall?contractName=${contractName}&phoneNumber=${phoneNumber}`,
    {
      ...reqData,
      method: 'POST',
    },
  );
  responseHandler(data);
  if (!data.ok) {
    const res = await data.json();
    sentryWrapper('sendCodeBySMS', data.status, res);
  }
};

/**
 * Подтверждает контакт контрагента по идентификатору договора и токену для подтверждения контакта
 * @param contactId - Идентификатор контакта.
 * @param verificationToken - Токен для подтверждения контакта.
 */
export const verifyContact = async (
  contactId: number,
  verificationToken: string,
): Promise<void> => {
  const data = await fetch(
    `${baseUrl}/Contacts/Contact/VerifyContact?contactId=${contactId}&verificationToken=${verificationToken}`,
    {
      ...reqData,
      method: 'POST',
      headers: getHeadersWithAuth(),
    },
  );

  responseHandler(data);

  if (!data.ok) {
    sentryWrapper('VerifyContact', data.status, data);
  }
};

/**
 * Возвращает список подписок на группы уведомлений для договора.
 */
export const getSubscriptions = async (): Promise<Subscriptions[]> => {
  const data = await fetch(`${baseUrl}/Contacts/Contact/GetSubscriptions`, {
    ...reqData,
    headers: getHeadersWithAuth(),
  });
  const res = await data.json();
  responseHandler(data);
  if (!data.ok) sentryWrapper('GetSubscriptions', data.status, res);

  return res;
};

/**
 * Возвращает информацию о текущий настройках тихого часа.
 */
export const getMutePeriodInfo = async (): Promise<MutePeriod> => {
  const data = await fetch(`${baseUrl}/Contacts/Contact/GetMutePeriodInfo`, {
    ...reqData,
    headers: getHeadersWithAuth(),
  });
  const res = await data.json();
  responseHandler(data);
  if (!data.ok) sentryWrapper('GetMutePeriodInfo', data.status, res);

  return res;
};

/**
 * Устанавливает период тихого часа
 */
export const setMutePeriod = async (
  hourFrom: number,
  hourTo: number,
  isImportantEnabled: boolean,
  isActive: boolean,
): Promise<void> => {
  const requestData = {
    hourFrom,
    hourTo,
    isImportantEnabled,
    isActive,
  };

  const data = await fetch(`${baseUrl}/Contacts/Contact/SetMutePeriod`, {
    ...reqData,
    method: 'POST',
    headers: getHeadersWithAuth({
      'Content-Type': 'application/json-patch+json',
    }),
    body: JSON.stringify(requestData),
  });

  responseHandler(data);

  if (!data.ok) {
    sentryWrapper('SetMutePeriod', data.status, data);
  }
};

/**
 * Устанавливает подписки контактов на группы уведомлений для договора
 */
export const notificationSubscription = async (
  eventGroupId: number,
  contactId: number,
  isSubscribed: boolean,
): Promise<void> => {
  const requestData = [
    {
      eventGroupId,
      contactId,
      isSubscribed,
    },
  ];

  const data = await fetch(`${baseUrl}/Contacts/Contact/SetSubscriptions`, {
    ...reqData,
    method: 'POST',
    headers: getHeadersWithAuth({
      'Content-Type': 'application/json-patch+json',
    }),
    body: JSON.stringify(requestData),
  });

  responseHandler(data);

  if (!data.ok) {
    sentryWrapper('notificationSubscription', data.status, data);
  }
};

/** Авторизация по временному паролю */
export const authByTemporaryPassword = async (
  contractName: string,
  password: string,
): Promise<{ token: string }> => {
  const data = await fetch(
    `${baseUrl}/Security/Auth/AuthByTemporaryPassword?contractName=${contractName}&temporaryPassword=${password}`,
    {
      ...reqData,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    },
  );
  const res = await data.json();
  if (!data.ok) sentryWrapper('authByTemporaryPassword', data.status, res);
  return res;
};

/**
 * Получает информацию о зомбировании
 */
export const getStrayInfo = async (): Promise<ZombieInfoProps> => {
  const data = await fetch(
    `${baseUrl}/OperatingState/OperatingState/StrayInfoGet`,
    {
      ...reqData,
      headers: getHeadersWithAuth(),
    },
  );
  const res = await data.json();
  responseHandler(data);
  if (!data.ok) sentryWrapper('getStrayInfo', data.status, res);
  return res;
};

/** Изменяет старый пин на введённый новый */
export const changePinCode = async (
  newPin: string,
  currentPassword: string,
): Promise<void> => {
  const data = await fetch(
    `${baseUrl}/Security/PasswordRecovery/ChangePin?newPin=${newPin}&currentPassword=${currentPassword}`,
    {
      ...reqData,
      method: 'POST',
      headers: getHeadersWithAuth(),
    },
  );
  responseHandler(data);
  if (!data.ok) {
    sentryWrapper('changePinCode', data.status, data);
  }
};

/**
 * Возвращает информацию об абоненте, который заключил договор и ожидает подключение
 */
export const getNewContractInfo = async (): Promise<NewContractProps> => {
  const data = await fetch(
    `${baseUrl}/OperatingState/OperatingState/GetNewContractInfo`,
    {
      ...reqData,
      headers: getHeadersWithAuth(),
    },
  );
  const res = await data.json();
  responseHandler(data);
  if (!data.ok) sentryWrapper('getNewContractInfo', data.status, res);

  return res;
};

/** Устанавливает значение флага, разрешающего аутентификацию и авторизацию по IP-адресу */
export const changeIpAuthAllow = async (
  password: string,
  isAllowed: boolean,
): Promise<void> => {
  const data = await fetch(
    `${baseUrl}/Security/PasswordRecovery/SetIpAuthAllow?password=${password}&isAllowed=${isAllowed}`,
    {
      ...reqData,
      method: 'POST',
      headers: getHeadersWithAuth(),
    },
  );
  responseHandler(data);
  if (!data.ok) {
    sentryWrapper('changeIpAuthAllow', data.status, data);
  }
};

/**
 * Возвращает коллекцию пассивных сообщений
 */
export const getPassiveMessages = async (): Promise<PassiveMessageProps[]> => {
  const data = await fetch(`${baseUrl}/Contacts/Contact/GetPassiveMessages`, {
    ...reqData,
    headers: getHeadersWithAuth(),
  });
  const res = await data.json();
  responseHandler(data);

  if (!data.ok) sentryWrapper('getPassiveMessages', data.status, res);
  return res;
};

/**
 * Возвращает список наименований товаров, входящих в базовую стоимость продукта на договоре
 */
export const getBasePricesItemsNames = async (
  tariffId?: number,
): Promise<string[]> => {
  const data = await fetch(
    `${baseUrl}/Tax/Tax/GetBasePricesItemsNames?tariffId=${tariffId}`,
    {
      ...reqData,
      headers: getHeadersWithAuth(),
    },
  );
  const res = await data.json();
  responseHandler(data);
  if (!data.ok) sentryWrapper('getBasePricesItemsNames', data.status, res);

  return res.basePricesItemsNames;
};

/**
 * Возвращает число заявок, у которых есть непрочитанные комментарии
 */
export const getDemandUnreadCount = async (): Promise<number> => {
  const data = await fetch(`${baseUrl}/Demand/Demand/GetDemandUnreadCount`, {
    ...reqData,
    headers: getHeadersWithAuth(),
  });
  const res = await data.json();
  responseHandler(data);
  if (!data.ok) sentryWrapper('getDemandUnreadCount', data.status, res);

  return res.count;
};

/**
 * Возвращает информацию о результатах расчёта для трансформера
 */
export const getTransformerEstimateDetails = async (
  tariffId: number,
  dateFrom: string,
  dateTrim: string,
): Promise<TransformLimitExceededProps[]> => {
  const data = await fetch(
    `${baseUrl}/Itv/Transformer/TransformerShowEstimateDetails?tariffId=${tariffId}&dateFrom=${dateFrom}&dateTrim=${dateTrim}`,
    {
      ...reqData,
      headers: getHeadersWithAuth(),
    },
  );
  const res = await data.json();
  responseHandler(data);
  if (!data.ok)
    sentryWrapper('getTransformerEstimateDetails', data.status, res);
  return res;
};

/**
 * Возвращает историю финансовых операций по договору (деньги) за период
 */
export const getMoneyHistory = async (
  fromDate: string,
  trimDate: string,
): Promise<MoneyHistoryProps[]> => {
  const data = await fetch(
    `${baseUrl}/Finances/Contract/GetMoneyHistory?fromDate=${fromDate}&trimDate=${trimDate}`,
    {
      ...reqData,
      headers: getHeadersWithAuth(),
    },
  );
  const res = await data.json();
  responseHandler(data);
  if (!data.ok) sentryWrapper('getMoneyHistory', data.status, res);
  return res;
};

/**
 * Возвращает список пользовательских заявок на договоре
 */
export const getDemandList = async (): Promise<AppealData[]> => {
  const data = await fetch(`${baseUrl}/Demand/Demand/GetDemandList`, {
    ...reqData,
    headers: getHeadersWithAuth(),
  });
  const res = await data.json();
  responseHandler(data);
  if (!data.ok) sentryWrapper('getDemandList', data.status, res);

  return res;
};

/** Создаёт комментарий к заявке */
export const createDemandComment = async (
  demandId: number,
  content: string,
): Promise<void> => {
  const requestData = {
    demandId,
    content,
  };
  const data = await fetch(`${baseUrl}/Demand/Demand/DemandCommentCreate`, {
    ...reqData,
    method: 'POST',
    headers: getHeadersWithAuth({
      'Content-Type': 'application/json-patch+json',
    }),
    body: JSON.stringify(requestData),
  });
  if (!data.ok) sentryWrapper('createDemandComment', data.status, null);
};

/**
 * История финансовых операций по договору (чатлы) за период
 */
export const getChatleHistory = async (
  fromDate: string,
  trimDate: string,
): Promise<ChatleHistoryProps[]> => {
  const data = await fetch(
    `${baseUrl}/Chatle/Chatle/GetChatleHistory?fromDate=${fromDate}&trimDate=${trimDate}`,
    {
      ...reqData,
      headers: getHeadersWithAuth(),
    },
  );
  const res = await data.json();
  responseHandler(data);

  if (!data.ok) sentryWrapper('getChatleHistory', data.status, res);

  return res;
};

/**
 * Возвращает список комментариев к заявке
 */
export const getDemandCommentList = async (
  demandId: number,
): Promise<CommentData[]> => {
  const data = await fetch(
    `${baseUrl}/Demand/Demand/GetDemandCommentList?demandId=${demandId}`,
    {
      ...reqData,
      headers: getHeadersWithAuth(),
    },
  );
  const res = await data.json();
  responseHandler(data);
  if (!data.ok) sentryWrapper('getDemandCommentList', data.status, res);

  return res;
};

/**
 * Возвращает подробную информацию о расчёте по конфигурационной странице документа
 * @param printableNumberId - Идентификатор страницы конфигурационного документа.
 */
export const getExpandAdvantage = async (
  printableNumberId: number,
): Promise<ExpandAdvantageData[]> => {
  const data = await fetch(
    `${baseUrl}/Finances/Contract/GetExpandAdvantage?printableNumberId=${printableNumberId}`,
    {
      ...reqData,
      headers: getHeadersWithAuth(),
    },
  );
  const res = await data.json();
  responseHandler(data);
  if (!data.ok) sentryWrapper('getExpandAdvantage', data.status, res);
  return res;
};

/**
 * Создание обращения
 * @param demandWorkflow - тип заявки
 * @param main - заголовок
 * @param mem - описание
 * @param userDescription - данные клиента
 * @param phone - телефон
 * @param email - почта
 * @param demandCause - причина
 * @returns {Promise<{ data: number; status: number }>}
 */
export const createDemand = async (
  demandWorkflow: WORKFLOW_IDS,
  main: string,
  mem: string,
  userDescription: string,
  phone: string,
  email?: string,
  demandCause?: DEMAND_CAUSE,
): Promise<{ data: number; status: number }> => {
  const requestData = {
    demandWorkflow,
    main,
    mem,
    userDescription,
    phone,
    email,
    demandCause,
  };
  const data = await fetch(`${baseUrl}/Demand/Demand/CreateDemand`, {
    ...reqData,
    method: 'POST',
    headers: getHeadersWithAuth({
      'Content-Type': 'application/json-patch+json',
    }),
    body: JSON.stringify(requestData),
  });
  const res = await data.json();
  if (!data.ok) sentryWrapper('createDemand', data.status, res);

  return { data: res.demandId, status: data.status };
};

/**
 * Возвращает историю смены продуктов трансформеров за период
 */
export const getTransformerHistory = async (
  dateFrom: string,
  dateTrim: string,
): Promise<TransformerHistory[]> => {
  const data = await fetch(
    `${baseUrl}/Itv/Transformer/GetTransformerHistory?dateFrom=${dateFrom}&dateTrim=${dateTrim}`,
    {
      ...reqData,
      headers: getHeadersWithAuth(),
    },
  );
  const res = await data.json();
  responseHandler(data);
  if (!data.ok) sentryWrapper('getTransformerHistory', data.status, res);

  return res;
};

/**
 * Возвращает информацию о выполнении расчётов по договору за период, а также подробности расчёта
 */
export const getEstimate = async (): Promise<ExpandAdvantageData[]> => {
  const data = await fetch(`${baseUrl}/Finances/Contract/GetEstimate`, {
    ...reqData,
    headers: getHeadersWithAuth(),
  });
  const res = await data.json();
  responseHandler(data);
  if (!data.ok) sentryWrapper('getEstimate', data.status, res);
  return res;
};

/**
 * Получает значение флага "вход без пароля из дома" для договора
 */
export const getIsIpAuthAllowed = async (): Promise<boolean> => {
  const data = await fetch(
    `${baseUrl}/Security/PasswordRecovery/IsIpAuthAllowed`,
    {
      ...reqData,
      headers: getHeadersWithAuth(),
    },
  );
  const res = await data.json();
  responseHandler(data);
  if (!data.ok) sentryWrapper('getIsIpAuthAllowed', data.status, res);

  return res.isIpAuthAllowed;
};

/** Устанавливает признак "прочитан" у всех комментариев к заявке */
export const demandMarkCommentsAsRead = async (
  demandId: number,
): Promise<void> => {
  const data = await fetch(
    `${baseUrl}/Demand/Demand/DemandMarkCommentsAsRead?demandId=${demandId}`,
    {
      ...reqData,
      method: 'POST',
      headers: getHeadersWithAuth(),
    },
  );
  if (!data.ok) sentryWrapper('demandMarkCommentsAsRead', data.status, null);
};

/*
 * Получает информацию о заказанной услуге для текущего договора
 */
export const getPhoneInfo = async (): Promise<Phone[]> => {
  const data = await fetch(`${baseUrl}/Phone/Phone/GetPhoneInfo`, {
    ...reqData,
    headers: getHeadersWithAuth(),
  });
  const res = await data.json();
  if (!data.ok) sentryWrapper('getPhoneInfo', data.status, res);
  return res;
};

/*
 * Получает информацию о заказанной услуге для текущего договора
 * @param fromDate
 * @trimDate fromDate
 * @trimDate byDay - Выборка по дням
 */
export const getPhoneStatistics = async (
  fromDate: string,
  trimDate: string,
  byDay: boolean,
): Promise<StatisticProps[]> => {
  const data = await fetch(
    `${baseUrl}/Phone/Phone/GetPhoneStatistics?fromDate=${fromDate}&trimDate=${trimDate}&byDay=${byDay}`,
    {
      ...reqData,
      headers: getHeadersWithAuth(),
    },
  );
  const res = await data.json();
  if (!data.ok) sentryWrapper('getPhoneStatistics', data.status, res);
  return res;
};

/*
 * Получает ДВО по идентификатору договора (переадресация)
 */
export const getForwardingRules = async (): Promise<ForwardingRule[]> => {
  const data = await fetch(`${baseUrl}/Phone/Phone/GetForwardingRules`, {
    ...reqData,
    headers: getHeadersWithAuth(),
  });
  const res = await data.json();
  if (!data.ok) sentryWrapper('getForwardingRules', data.status, res);
  return res;
};

/*
 * Возвращает цены на звонки
 */
export const getTariffVoicePrice = async (
  tariffId: number,
): Promise<Price[]> => {
  const data = await fetch(
    `${baseUrl}/Phone/Phone/GetTariffVoicePrice?tariffId=${tariffId}`,
    {
      ...reqData,
      headers: getHeadersWithAuth(),
    },
  );
  const res = await data.json();
  if (!data.ok) sentryWrapper('getTariffVoicePrice', data.status, res);
  return res;
};

/*
 * Выполняет поиск стоимости звонка по указанному коду направления для указанного контракта
 */
export const getCallPrice = async (
  directionCode: string,
  query: string,
): Promise<CodePrice[]> => {
  const data = await fetch(
    `${baseUrl}/Phone/Phone/GetCallPrice?directionCode=${directionCode}&query=${query}`,
    {
      ...reqData,
      headers: getHeadersWithAuth(),
    },
  );
  const res = await data.json();
  if (!data.ok) sentryWrapper('getCallPrice', data.status, res);
  return res;
};

/*
 * Проверяет возможность присоединения телефонного номера к договору
 */
export const phoneBindCheck = async (): Promise<void> => {
  const data = await fetch(`${baseUrl}/Phone/Phone/PhoneBindCheck`, {
    ...reqData,
    headers: getHeadersWithAuth(),
  });
  if (!data.ok) {
    const res = await data.json();
    sentryWrapper('phoneBindCheck', data.status, res);
  }
};

/*
 * Присоединяет телефонный номер к договору
 * @param satellite - Идентификатор зоны связи.
 */
export const bindPhone = async (satellite: number): Promise<number> => {
  const data = await fetch(
    `${baseUrl}/Phone/Phone/PhoneBind?satellite=${satellite}`,
    {
      ...reqData,
      method: 'POST',
      headers: getHeadersWithAuth(),
    },
  );
  const res = await data.json();
  if (!data.ok) sentryWrapper('bindPhone', data.status, res);
  return res.phoneId;
};

/*
 * Возвращает список телефонных зон, из которых указанный договор может заказать номер
 */
export const getPhoneSatelliteList = async (): Promise<Satellite[]> => {
  const data = await fetch(`${baseUrl}/Phone/Phone/GetPhoneSatelliteList`, {
    ...reqData,
    headers: getHeadersWithAuth(),
  });
  const res = await data.json();
  if (!data.ok) sentryWrapper('getPhoneSatelliteList', data.status, res);
  return res;
};

/*
 * Установка пароля для телефонного сервиса
 * @param phoneNumber - номер телефона
 * @param newPassword - новый пароль
 */
export const setPhonePassword = async (
  phoneNumber: string,
  newPassword: string,
): Promise<void> => {
  const data = await fetch(
    `${baseUrl}/Phone/Phone/SetPhonePassword?phoneNumber=${phoneNumber}&newPassword=${newPassword}`,
    {
      ...reqData,
      method: 'POST',
      headers: getHeadersWithAuth(),
    },
  );
  if (!data.ok) {
    const res = await data.json();
    sentryWrapper('setPhonePassword', data.status, res);
  }
};

/*
 * Установка пароля для телефонного сервиса
 * @param SimId - Идентификатор ресурса.
 * @param IsEnabled - Включено или выключено правило переадресации.
 * @param State - Условие срабатывания переадресации.
 * @param ForwardNumber - Номер переадресации.
 * @param Timeout - Длительность периода времени в секундах, являющаяся признаком отсутствия ответа.
 * @param Id - Идентификатор настройки переадресации.
 * @param WeekDays - Список дней недели, в которые работает переадресация (1 - понедельник, 7 - воскресенье).
 * @param StartTime - Время начала действия правила переадресации.
 * @param EndTime - Время окончания действия правила переадресации.
 */
export const saveForwardingRule = async (
  SimId: number,
  IsEnabled: boolean,
  State: StateCondition,
  ForwardNumber: string,
  Timeout: number,
  Id: number,
  WeekDays: number[],
  StartTime: string,
  EndTime: string,
): Promise<void> => {
  const days = WeekDays.map((item) => {
    return `&WeekDays=${item}`;
  }).join('');
  const data = await fetch(
    `${baseUrl}/Phone/Phone/SaveForwardingRule?SimId=${SimId}&IsEnabled=${IsEnabled}&State=${State}&ForwardNumber=${ForwardNumber}&Timeout=${Timeout}&Id=${Id}${days}&StartTime=${StartTime}&EndTime=${EndTime}`,
    {
      ...reqData,
      method: 'POST',
      headers: getHeadersWithAuth(),
    },
  );
  if (!data.ok) {
    const res = await data.json();
    sentryWrapper('saveForwardingRule', data.status, res);
  }
};

/*
 * Удаление настроек переадресации из таблицы RTU.additional_forwarding
 * @param forwardingRuleId - Идентификатор настройки переадресации.
 */
export const deleteForwardingRule = async (
  forwardingRuleId: number,
): Promise<void> => {
  const data = await fetch(
    `${baseUrl}/Phone/Phone/DeleteForwardingRule?forwardingRuleId=${forwardingRuleId}`,
    {
      ...reqData,
      method: 'POST',
      headers: getHeadersWithAuth(),
    },
  );
  if (!data.ok) {
    const res = await data.json();
    sentryWrapper('deleteForwardingRule', data.status, res);
  }
};

/*
 * Проверяет возможность открепления типа "Телефонный номер" с договора
 * @param simId - Идентификатор ресурса (телефонного номера).
 */
export const phoneUnbindCheck = async (simId: number): Promise<void> => {
  const data = await fetch(
    `${baseUrl}/Phone/Phone/PhoneUnbindCheck?simId=${simId}`,
    {
      ...reqData,
      headers: getHeadersWithAuth(),
    },
  );
  if (!data.ok) {
    const res = await data.json();
    sentryWrapper('phoneUnbindCheck', data.status, res);
  }
};

/*
 * Открепляет реквизит типа "Телефонный номер" с договора завтрашним числом
 * @param simId - Идентификатор ресурса (телефонного номера).
 */
export const phoneUnbind = async (simId: number): Promise<void> => {
  const data = await fetch(
    `${baseUrl}/Phone/Phone/PhoneUnbind?simId=${simId}`,
    {
      ...reqData,
      method: 'POST',
      headers: getHeadersWithAuth(),
    },
  );
  if (!data.ok) {
    const res = await data.json();
    sentryWrapper('phoneUnbind', data.status, res);
  }
};

/*
 * Отменяет открепление номера в течении суток
 * @param simId - Идентификатор ресурса (телефонного номера).
 */
export const phoneUnbindDocumentClear = async (
  simId: number,
): Promise<void> => {
  const data = await fetch(
    `${baseUrl}/Phone/Phone/PhoneUnbindDocumentClear?simId=${simId}`,
    {
      ...reqData,
      method: 'POST',
      headers: getHeadersWithAuth(),
    },
  );
  if (!data.ok) {
    const res = await data.json();
    sentryWrapper('phoneUnbindDocumentClear', data.status, res);
  }
};

/*
 * Возвращает соглашение для отключения телефонного номера
 * @param simId - Идентификатор ресурса (телефонного номера).
 */
export const getPhoneUnbindAgreement = async (
  simId: number,
): Promise<string> => {
  const data = await fetch(
    `${baseUrl}/Phone/Phone/GetPhoneUnbindAgreement?simId=${simId}`,
    {
      ...reqData,
      headers: getHeadersWithAuth(),
    },
  );
  const res = await data.json();
  if (!data.ok) sentryWrapper('getPhoneUnbindAgreement', data.status, res);
  return res.data;
};

/*
 * Информация о возможности сменить продукт.
 * @param seriesCode - Код серии желаемого продукта.
 */
export const checkTariffSwitch = async (
  tariffId: number,
): Promise<{
  status: { errorCode: string; code: string; description: string };
}> => {
  const data = await fetch(
    `${baseUrl}/Tax/Tax/CheckTariffSwitch?tariffId=${tariffId}`,
    {
      ...reqData,
      method: 'POST',
      headers: getHeadersWithAuth(),
    },
  );

  if (!data.ok) {
    const res = await data.json();
    sentryWrapper('checkTariffSwitch', data.status, res);
    return res;
  }
  return null;
};

/*
 * Возвращает параметры телефонии на продукте
 * @param satellite
 */
export const getPhoneData = async (satellite: number): Promise<Agreement> => {
  const data = await fetch(
    `${baseUrl}/Phone/Phone/GetPhoneBindData?satellite=${satellite}`,
    {
      ...reqData,
      headers: getHeadersWithAuth(),
    },
  );
  const res = await data.json();
  if (!data.ok) sentryWrapper('getPhoneBindData', data.status, res);
  return res;
};

/**
 * Возвращает список классов значений контактов
 */
export const getContactValueClasses = async (): Promise<ContactType[]> => {
  const data = await fetch(
    `${baseUrl}/Contacts/Contact/GetContactValueClasses`,
    { ...reqData },
  );

  const res = await data.json();
  responseHandler(data);
  if (!data.ok) sentryWrapper('getContactValueClasses', data.status, res);

  return res;
};

/**
 * Возвращает параметры мобильного накопителя конктракта
 * @returns {Promise<MobileStorageBalances>}
 */
export const getMobileStorageBalances = async (): Promise<MobileStorageBalances> => {
  const data = await fetch(
    `${baseUrl}/Mobile/MobileBalance/GetContractMobileBalances`,
    { ...reqData, headers: getHeadersWithAuth() },
  );

  const res = await data.json();
  responseHandler(data);
  if (!data.ok) sentryWrapper('getMobileStorageBalances', data.status, res);

  return res;
};

/**
 * Получает информацию о подключенных мобильных номерах договора
 * @returns {Promise<MobilePhoneInfo>}
 */
export const getMobilePhones = async (): Promise<MobilePhoneInfo> => {
  const data = await fetch(
    `${baseUrl}/Mobile/MobilePhoneNumber/GetContractResourceMobiles`,
    { ...reqData, headers: getHeadersWithAuth() },
  );

  const res = await data.json();
  responseHandler(data);
  if (!data.ok) sentryWrapper('getMobilePhones', data.status, res);

  return res;
};

/**
 * Открепляет мобильный телефонный номер с договора
 * @param simId - Идентификатор ресурса "Мобильный телефонный номер", который нужно открепить
 * @returns {Promise<void>}
 */
export const unbindMobilePhone = async (simId: number): Promise<void> => {
  const data = await fetch(
    `${baseUrl}/Mobile/MobilePhoneNumber/UnbindMobilePhoneNumber?simId=${simId}`,
    {
      ...reqData,
      method: 'POST',
      headers: getHeadersWithAuth(),
    },
  );
  if (!data.ok) {
    const res = await data.json();
    sentryWrapper('unbindMobilePhone', data.status, res);
  }
};

/**
 * Блокирует мобильный телефонный номер на договоре
 * @param simId - Идентификатор ресурса "Мобильный телефонный номер", который нужно заблокировать
 * @returns {Promise<void>}
 */
export const blockMobilePhone = async (simId: number): Promise<void> => {
  const data = await fetch(
    `${baseUrl}/Mobile/MobilePhoneNumber/BlockMobilePhoneNumber?simId=${simId}`,
    {
      ...reqData,
      method: 'POST',
      headers: getHeadersWithAuth(),
    },
  );
  if (!data.ok) {
    const res = await data.json();
    sentryWrapper('blockMobilePhone', data.status, res);
  }
};

/**
 * Удаляет блокировку мобильного телефонного номера на договоре
 * @param simId - Идентификатор ресурса "Мобильный телефонный номер", который нужно заблокировать
 * @returns {Promise<void>}
 */
export const unblockMobilePhoneNumber = async (
  simId: number,
): Promise<void> => {
  const data = await fetch(
    `${baseUrl}/Mobile/MobilePhoneNumber/UnblockMobilePhoneNumber?simId=${simId}`,
    {
      ...reqData,
      method: 'POST',
      headers: getHeadersWithAuth(),
    },
  );
  if (!data.ok) {
    const res = await data.json();
    sentryWrapper('UnblockMobilePhoneNumber', data.status, res);
  }
};

/**
 * Получает соглашение для открепления мобильного телефонного номера
 * @param simId - Идентификатор ресурса "Мобильный телефонный номер"
 * @returns {Promise<MobilePhoneInfo>}
 */
export const getUnbindMobilePhoneNumberAgreement = async (
  simId: number,
): Promise<string> => {
  const data = await fetch(
    `${baseUrl}/Mobile/MobilePhoneNumber/GetUnbindMobilePhoneNumberAgreement?simId=${simId}`,
    { ...reqData, headers: getHeadersWithAuth() },
  );

  const res = await data.json();
  if (!data.ok)
    sentryWrapper('getUnbindMobilePhoneNumberAgreement', data.status, res);
  return res.data;
};

/**
 * Получает соглашение для добровольной блокировки мобильного телефонного номера
 * @param simId - Идентификатор ресурса "Мобильный телефонный номер"
 * @returns {Promise<MobilePhoneInfo>}
 */
export const getBlockMobilePhoneNumberAgreement = async (
  simId: number,
): Promise<string> => {
  const data = await fetch(
    `${baseUrl}/Mobile/MobilePhoneNumber/GetBlockMobilePhoneNumberAgreement?simId=${simId}`,
    { ...reqData, headers: getHeadersWithAuth() },
  );

  const res = await data.json();
  if (!data.ok)
    sentryWrapper('GetBlockMobilePhoneNumberAgreement', data.status, res);
  return res.data;
};

/**
 * Изменяет имя мобильного номера
 * @param simId - идентификатор ресурса "Мобильный телефонный номер", которому меняется имя
 * @param subscriberName - новое имя для мобильного номера
 * @returns {Promise<void>}
 */
export const changeNameForMobilePhone = async (
  simId: number,
  subscriberName: string,
): Promise<void> => {
  const data = await fetch(
    `${baseUrl}/Mobile/MobilePhoneNumber/SetSubscriberNameForMobileResource?simId=${simId}&subscriberName=${subscriberName}`,
    {
      ...reqData,
      method: 'POST',
      headers: getHeadersWithAuth(),
    },
  );
  if (!data.ok) {
    const res = await data.json();
    sentryWrapper('changeNameForMobilePhone', data.status, res);
  }
};

/**
 * Получает соглашение для замены SIM карты
 * @param simId - Идентификатор ресурса "Мобильный телефонный номер"
 * @returns {Promise<string>}
 */
export const getReplaceSimCardAgreement = async (
  simId: number,
): Promise<string> => {
  const data = await fetch(
    `${baseUrl}/Mobile/MobilePhoneNumber/GetReplaceSimCardAgreement?simId=${simId}`,
    { ...reqData, headers: getHeadersWithAuth() },
  );
  const res = await data.json();
  if (!data.ok) sentryWrapper('GetReplaceSimCardAgreement', data.status, res);
  return res.data;
};

/**
 * Заказывает замену SIM карты
 * @param simId - Идентификатор ресурса "Мобильный телефонный номер"
 * @returns {Promise<void>}
 */
export const replaceSimCard = async (
  simId: number,
  deliveryType: DELIVERY_TYPE,
  deliveryAddress: string,
): Promise<void> => {
  const simCardDeliveryInfo = {
    deliveryType,
    deliveryAddress,
  };
  const requestData = {
    simId,
    simCardDeliveryInfo,
  };
  const data = await fetch(
    `${baseUrl}/Mobile/MobilePhoneNumber/ReplaceSimCard`,
    {
      ...reqData,
      method: 'POST',
      headers: getHeadersWithAuth({
        'Content-Type': 'application/json-patch+json',
      }),
      body: JSON.stringify(requestData),
    },
  );
  if (!data.ok) {
    const res = await data.json();
    sentryWrapper('replaceSimCard', data.status, res);
  }
};

/**
 * Возвращает доступные категории мобильных номеров
 * @returns {Promise<MobilePhoneNumberCategories>}
 */
export const getMobilePhoneNumberCategories = async (): Promise<MobilePhoneNumberCategories> => {
  const data = await fetch(
    `${baseUrl}/Mobile/MobilePhoneNumber/GetMobilePhoneNumberCategories`,
    {
      ...reqData,
      headers: getHeadersWithAuth(),
    },
  );
  const res = await data.json();
  responseHandler(data);
  if (!data.ok)
    sentryWrapper('getMobilePhoneNumberCategories', data.status, res);

  return res;
};

/**
 * Возвращает доступные мобильные номера для договора
 * @returns {Promise<AvailableMobilePhoneNumbers>}
 */
export const getAvailableMobilePhoneNumbers = async (): Promise<AvailableMobilePhoneNumbers> => {
  const data = await fetch(
    `${baseUrl}/Mobile/MobilePhoneNumber/GetAvailableMobilePhoneNumbers`,
    {
      ...reqData,
      headers: getHeadersWithAuth(),
    },
  );
  const res = await data.json();
  responseHandler(data);
  if (!data.ok)
    sentryWrapper('getAvailableMobilePhoneNumbers', data.status, res);

  return res;
};

/**
 * Получает соглашение для замены мобильного номера телефона
 * @param simId - идентификатор ресурса "Мобильный телефонный номер", для которого происходит замена номера
 * @param mobilePhoneNumberId - идентификатор нового мобильного телефонного номера.
 * @returns {Promise<string>}
 */
export const getReplaceMobilePhoneNumberAgreement = async (
  simId: number,
  mobilePhoneNumberId: number,
): Promise<string> => {
  const data = await fetch(
    `${baseUrl}/Mobile/MobilePhoneNumber/GetReplaceMobilePhoneNumberAgreement?simId=${simId}&mobilePhoneNumberId=${mobilePhoneNumberId}`,
    {
      ...reqData,
      headers: getHeadersWithAuth(),
    },
  );
  const res = await data.json();
  responseHandler(data);
  if (!data.ok)
    sentryWrapper('getReplaceMobilePhoneNumberAgreement', data.status, res);

  return res.data;
};

/**
 * Изменяет имя мобильного номера
 * @param simId - идентификатор ресурса "Мобильный телефонный номер", которому меняется имя
 * @param mobilePhoneNumberId - идентификатор нового мобильного телефонного номера
 * @returns {Promise<void>}
 */
export const replaceMobilePhoneNumber = async (
  simId: number,
  mobilePhoneNumberId: number,
): Promise<void> => {
  const data = await fetch(
    `${baseUrl}/Mobile/MobilePhoneNumber/ReplaceMobilePhoneNumber?simId=${simId}&mobilePhoneNumberId=${mobilePhoneNumberId}`,
    {
      ...reqData,
      method: 'POST',
      headers: getHeadersWithAuth(),
    },
  );
  if (!data.ok) {
    const res = await data.json();
    sentryWrapper('replaceMobilePhoneNumber', data.status, res);
  }
};

/**
 * Получает соглашение для прикрепления нового мобильного номера телефона
 * @param mobilePhoneNumberId - идентификатор нового мобильного телефонного номера.
 * @returns {Promise<string>}
 */
export const getBindMobilePhoneNumberAgreement = async (
  mobilePhoneNumberId: number,
): Promise<string> => {
  const data = await fetch(
    `${baseUrl}/Mobile/MobilePhoneNumber/GetBindMobilePhoneNumberAgreement?mobilePhoneNumberId=${mobilePhoneNumberId}`,
    {
      ...reqData,
      headers: getHeadersWithAuth(),
    },
  );
  const res = await data.json();
  responseHandler(data);
  if (!data.ok)
    sentryWrapper('getBindMobilePhoneNumberAgreement', data.status, res);

  return res.data;
};

/**
 * Производит покупку мобильного телефонного номера для договора
 * @param mobilePhoneNumberId - идентификатор нового мобильного телефонного номера
 * @param deliveryType - тип доставки SIM-карты
 * @param deliveryAddress - адрес доставки SIM-карты
 * @returns {Promise<number>} - возвращает новый идентификатор ресурса "Мобильный телефонный номер"
 */
export const bindMobilePhoneNumber = async (
  mobilePhoneNumberId: number,
  deliveryType: DELIVERY_TYPE,
  deliveryAddress: string,
): Promise<number> => {
  const requestData = {
    mobilePhoneNumberId,
    simCardDeliveryInfo: {
      deliveryType,
      deliveryAddress,
    },
  };

  const data = await fetch(
    `${baseUrl}/Mobile/MobilePhoneNumber/BindMobilePhoneNumber`,
    {
      ...reqData,
      method: 'POST',
      headers: getHeadersWithAuth({
        'Content-Type': 'application/json-patch+json',
      }),
      body: JSON.stringify(requestData),
    },
  );

  const res = await data.json();
  responseHandler(data);
  if (!data.ok) sentryWrapper('bindMobilePhoneNumber', data.status, res);

  return res.simId;
};

/**
 * Проверяет возможность переноса мобильного телефонного номера пользователя в Планету
 * @param mobilePhoneNumber - идентификатор нового мобильного телефонного номера
 * @returns {Promise<void>}
 */
export const checkPortabilityMobilePhoneNumber = async (
  mobilePhoneNumber: string,
): Promise<void> => {
  const data = await fetch(
    `${baseUrl}/Mobile/MobileNumberPortability/CheckPortabilityMobilePhoneNumber?mobilePhoneNumber=${mobilePhoneNumber}`,
    {
      ...reqData,
      method: 'POST',
      headers: getHeadersWithAuth(),
    },
  );
  if (!data.ok) {
    const res = await data.json();
    sentryWrapper('replaceMobilePhoneNumber', data.status, res);
  }
};

/**
 * Производит перенос стороннего мобильного телефонного номера в Планету и прикрепляет к договору временный номер
 * @param mobilePhoneNumber - мобильный телефонный номер пользователя, для которого будет произведен перенос
 * @param deliveryType - тип доставки SIM-карты
 * @param deliveryAddress - адрес доставки SIM-карты
 * @returns {Promise<number>} - возвращает новый идентификатор ресурса "Мобильный телефонный номер"
 */
export const bindTemporaryMobilePhoneNumberForPortation = async (
  mobilePhoneNumber: string,
  deliveryType: DELIVERY_TYPE,
  deliveryAddress: string,
): Promise<number> => {
  const requestData = {
    mobilePhoneNumber,
    simCardDeliveryInfo: {
      deliveryType,
      deliveryAddress,
    },
  };

  const data = await fetch(
    `${baseUrl}/Mobile/MobileNumberPortability/InitMobilePhoneNumberPortability`,
    {
      ...reqData,
      method: 'POST',
      headers: getHeadersWithAuth({
        'Content-Type': 'application/json-patch+json',
      }),
      body: JSON.stringify(requestData),
    },
  );

  const res = await data.json();
  responseHandler(data);
  if (!data.ok)
    sentryWrapper(
      'bindTemporaryMobilePhoneNumberForPortation',
      data.status,
      res,
    );

  return res.simId;
};
