export enum STORAGE_TYPE {
  CALL = 'OutgoingCall',
  SMS = 'OutgoingSms',
  INTERNET = 'MobileInternet',
  ROAMING_INTERNET_ZONE_1 = 'InternationalRoamingMobileInternetZone1',
  ROAMING_INTERNET_ZONE_2 = 'InternationalRoamingMobileInternetZone2',
  ROAMING_INTERNET_ZONE_3 = 'InternationalRoamingMobileInternetZone3',
  ROAMING_INTERNET_ZONE_4 = 'InternationalRoamingMobileInternetZone4',
}

export enum UNIT_TYPE {
  Byte = 'Byte',
  MegaByte = 'MegaByte',
  GigaByte = 'GigaByte',
  Minute = 'Minute',
  Unit = 'Unit',
  Month = 'Month',
  Day = 'Day',
  Second = 'Second',
}

/** Информация о накопительных балансах */
export interface MobileStorage {
  mobileBalanceType: STORAGE_TYPE;
  unit: UNIT_TYPE;
  accrualQuantity: number;
  maxQuantity: number;
  startQuantity: number;
  currentQuantity: number;
  isOverflow: boolean;
}

/** Информация о балансах, связанных с экземплярами подписок */
export interface MobileSubscription {
  mobileBalanceType: STORAGE_TYPE;
  unit: UNIT_TYPE;
  fromDt: string;
  trimDt: string;
  currentQuantity: number;
  startQuantity: number;
}

/** Информация о балансах накопителя договора */
export interface MobileStorageBalances {
  storageMobileBalances: MobileStorage[];
  itemSubscriptionMobileBalances: MobileSubscription[];
}

/** Информация о подключенных мобильных номерах */
export interface MobilePhoneInfo {
  simCardReplacePrice: number;
  simCardCourierDeliveryPrice: number;
  contractResourceMobiles: ContractResourceMobiles[];
}

export interface ContractResourceMobiles {
  simId: number;
  iccid: string;
  simCardId: number;
  msisdn: string;
  availableMobileActions: string[];
  isActive: boolean;
  subscriberName: string;
}

export interface ScaleProps {
  type: STORAGE_TYPE;
}

export enum DELIVERY_TYPE {
  /** Самовывоз из офисов */
  SELF_PICKUP = 'SelfPickup',
  /** Доставка курьером */
  COURIER = 'Courier',
}

export interface SimCardDeliveryInfo {
  deliveryType: DELIVERY_TYPE;
  deliveryAddress: 'string';
}

interface MobilePhoneNumberCategorie {
  name: string;
  price: number;
  id: number;
}

export interface MobilePhoneNumberCategories {
  mobilePhoneNumberCategories: MobilePhoneNumberCategorie[];
}

export interface AvailableMobilePhoneNumber {
  id: number;
  msisdn: string;
  categoryId: number;
  isFromUsed: boolean;
}

export interface AvailableMobilePhoneNumbers {
  availableMobilePhoneNumbers: AvailableMobilePhoneNumber[];
}

export enum EXEPTIONS {
  CLIENT_REQUEST_DEMAND_EXEPTION = 'ClientRequestDemandExeption',
}

export enum MOBILE_ACTIONS {
  ReplaceSubscriberName = 'ReplaceSubscriberName',
  Unbind = 'Unbind',
  Block = 'Block',
  Unblock = 'Unblock',
  ReplaceNumber = 'ReplaceNumber',
  ReplaceSimCard = 'ReplaceSimCard',
  ActivateSimCard = 'ActivateSimCard',
}
