/** Интерфейсы */
import { format } from 'date-fns';
import { City } from '~/interfaces/CityInterface';
import { ContractAuth } from '~/interfaces/ContractAuth.interface';

/** id cookies хранящей - "new token" */
export const TOKEN = 'new_planeta_token';
/** id cookies хранящей - "Данные контракта" */
export const CONTRACT_AUTH = 'contract_auth';
/** id cookies хранящей - "Город" */
export const CITY_DATA = 'city_data';
/** Время смерти cookies и конец авторизации */
export const COOKIES_DEAD_TIME_IN_MINUTES = 20 * 60;
/** Время смерти cookies при выборе города */
export const COOKIES_CITY_DEAD_TIME_IN_DAYS = 999;
/** id cookies хранящей - "Список банков" */
export const SELECTED_BANKS = 'selected_banks';
/** Префикс номера договора */
export const CONTRACT_PREFIX = 'К-';
/** Заголовок стандартный */
export const DEFAULT_TITLE = 'Планета — интернет и ТВ';
/** Стандартное описание */
export const DEFAULT_DESCRIPTION =
  'Надёжный интернет на скорости до 1 Гбит/с и более 350 телеканалов. Подключай Планету домой!';
/** Ошибка */
export const DEFAULT_ERROR = 'Что-то пошло не так. Повторите попытку позже.';
/** сообщения об ошибках ввода номера телефона */
export const ERROR_PHONE = 'Введите корректный номер телефона';

/** максимальная длина телефона */
export const PHONE_NUMBER_LENGTH = 16;
/** значение телефона по умолчанию */
export const INIT_PHONE_VALUE = '+7 ';

/** Город, выбираемый по умолчанию */
export const DEFAULT_CITY: City = {
  id: 1,
  name: 'Екатеринбург',
  shortName: 'ЕКБ',
  satellite: -1,
};

/** Данные контракта по умолчанию */
export const DEFAULT_CONTRACT_AUTH: ContractAuth = {
  contractId: 0,
  clientName: 'userName',
  contractName: '0',
  balance: 0,
  cityId: null,
  token: '',
  dotInfo: {
    cityId: DEFAULT_CITY.id,
    streetName: '',
    buildingName: '',
    roomName: '',
  },
  authenticationMethod: null,
  otherClientContractNames: [],
};

/** типы подключения */
export enum ConnectionMethod {
  /* Медный */
  LAN = 'lan',
  /* Оптический */
  FTTH = 'ftth',
  GPON = 'gpon',
}

/** Статус договора */
export enum OPERATING_STATE {
  /** Обслуживается */
  ON = 'On',
  /** Обслуживание приостановлено из-за клиентской добровольной блокировки */
  CLIENT_BLOCK = 'ClientBlock',
  /** Договор расторгнут */
  DREGS = 'Dregs',
  /** Договор - зомби */
  STRAY = 'Stray',
  /** Новый договор (обслуживание ещё не начато) */
  NEW = 'New',
  /** Обслуживание заблокировано со стороны оператора за неуплату */
  PROVIDER_BLOCK_DEBT = 'ProviderBlockDebt',
}

/** Ошибка: Согласие на обработку персональных данных */
export const ERROR_AGREEMENT_TEXT =
  'Необходимо дать согласие на обработку персональных данных';

/** Главная страница */
export const PLANETA_URL = 'https://planeta.tc';

/** https://help.planeta.tc */
export const HELP_PLANETA_URL = 'https://help.planeta.tc';

/** Ссылка на популярные вопросы */
export const HELP_PLANETA_POPULAR_URL = `${HELP_PLANETA_URL}/glavnaya-2/populyarnye-voprosy`;

/** https://job.planeta.tc */
export const JOB_PLANETA_URL = 'https://job.planeta.tc';

/** http://tv.planeta.tc */
export const TV_PLANETA_URL = 'http://tv.planeta.tc';

/** http://tv.planeta.tc */
export const WINK_PLANETA_URL = 'https://wink.planeta.tc';

/** Ссылка на контакты */
export const CONTACTS_LINK = '/contacts';

/** Ссылка на документы */
export const DOCUMENTS_LINK = '/documents';

/** slug несуществующей страницы */
export const ERROR_SLUG = 'errorslug';

/** slug страницы оплаты */
export const PAYMENT_SLUG = 'payment';

/** slug оборудования и наименование одноимённого блока */
export const DEVICES = 'devices';

/** список slug личного кабинета */
export enum Pab2cSlugs {
  /** Главная страница */
  MAIN_SLUG = 'private',
  /** Страница для неавторизованного пользователя */
  PLUG_SLUG = 'private/plug',
  /** Страница смены продукта */
  CHANGE_PLAN_SLUG = 'compare',
  /** Страница результата платежа */
  CHECK_PAYMENT = 'checkpayment',
}

export const DEFAULT_LK_LINK = `/${Pab2cSlugs.MAIN_SLUG}`;

/** slug проверки авторизации сторонних сервисов */
export const AUTH_SLUG = 'auth';

/** slug игрового портала */
export const IGROVOY_SLUG = 'games';

/** slug страницы с лентой антивирусов */
export const SOFT_SLUG = 'soft';

/** slug ленты новостей */
export const NEWS_SLUG = 'news';

/** slug ленты голосовой связи */
export const VOICE_SLUG = 'voice';

/** slug ленты гигабит */
export const GIGABIT_SLUG = 'gigabit';

/** slug ленты телевидение */
export const TV_SLUG = 'tv';

/** slug ленты бонусы */
export const BONUS_SLUG = 'bonus';

/** slug ленты сверхтелевидение */
export const OVERTV_SLUG = 'overtv';

/** slug ленты сверхинтернет */
export const OVERINTERNET_SLUG = 'overinternet';

/** slug ленты интернет+тв */
export const TVNET_SLUG = 'tvnet';

/** slug ленты интернет */
export const INTERNET_SLUG = 'internet';

/** slug ленты малый бизнес */
export const SMALL_BUSINESS_SLUG = 'smallbusiness';

/** id городов */
export const CITIES = {
  ekb: 1,
  berez: 2,
  nt: 3,
  ku: 6,
  krur: 7,
};

/** Положительный статус Promise.allSettled */
export const FULFILLED = 'fulfilled';

/** slug для редиректа на wink */
export const WINK_REDIRECT_SLUG = 'get-wink';

export enum TEMPORARY_AUTH_QS {
  CONTACT_NAME = 'contract',
  TEMPORARY_TOKEN = 'token',
}

/** Правила валидации данных */
export const CELLPHONE_VALIDATE_REGEXP = /^89[\d]{9}$/;
export const EMAIL_VALIDATE_REGEXP = /^(?!\.)(?:(?!.*\.{2})(?:[a-z0-9\.\-_]{0,63}|[а-я0-9\.\-]{0,63})[a-zа-я0-9])@(?!\.)(?:[a-zа-я0-9\-\.]+)(?:(?:\.(?:[a-zа-я]){2,63})+)$/iu;
export const PASSWORD_MAX_LENGTH = 255;

/** Попытка */
export const ATTEMPT = ['попытка', 'попытки', 'попыток'];

/** Осталась */
export const REMAINED = ['Осталась', 'Осталось', 'Осталось'];

export enum BLOCK_NAMES {
  announcements = 'announcements',
  anyPayments = 'anyPayments',
  autoPayBanner = 'autoPayBanner',
  autoPayment = 'autoPayment',
  avatar = 'avatar',
  banner = 'banner',
  bonusProgramm = 'bonusProgramm',
  callToAction = 'callToAction',
  chatlesExp = 'chatlesExp',
  connectionSpeed = 'connectionSpeed',
  contractTrusted = 'contractTrusted',
  device = 'device',
  deviceList = 'deviceList',
  devices = 'devices',
  exampleBlock = 'exampleBlock',
  feedbackCustom = 'feedbackCustom',
  feedbackFlamp1 = 'feedbackFlamp1',
  feedbackFlamp2 = 'feedbackFlamp2',
  feedbackFlamp3 = 'feedbackFlamp3',
  feedbackFlamp4 = 'feedbackFlamp4',
  feedbackFlamp5 = 'feedbackFlamp5',
  feedbackFlamp6 = 'feedbackFlamp6',
  feedbackYandex1 = 'feedbackYandex1',
  feedbackYandex2 = 'feedbackYandex2',
  feedbackYandex3 = 'feedbackYandex3',
  feedbackYandex4 = 'feedbackYandex4',
  feedbackYandex5 = 'feedbackYandex5',
  feedbackYandex6 = 'feedbackYandex6',
  headerDescription = 'headerDescription',
  help = 'help',
  igrovoy = 'igrovoy',
  legal = 'legal',
  myStoriesContest = 'myStoriesContest',
  myTV = 'myTV',
  pab2cContract = 'pab2cContract',
  pab2cContractState = 'pab2cContractState',
  pab2cDevices = 'pab2cDevices',
  pab2cInternet = 'pab2cInternet',
  pab2cSettings = 'pab2cSettings',
  pab2cSubscriptions = 'pab2cSubscriptions',
  pab2cVoice = 'pab2cVoice',
  pab2cSoftline = 'pab2cSoftline',
  pab2cMobile = 'pab2cMobile',
  payment = 'payment',
  paymentInfo = 'paymentInfo',
  paymentResult = 'paymentResult',
  productPage = 'productPage',
  productSelector = 'productSelector',
  productSwitcher = 'productSwitcher',
  resiliencyBanner = 'resiliencyBanner',
  sberATM = 'sberATM',
  sberSMS = 'sberSMS',
  secondMenu = 'secondMenu',
  sendWish = 'sendWish',
  singleAnnouncement = 'singleAnnouncement',
  softline = 'softline',
  vacation = 'vacation',
  timeline = 'timeline',
  smartTV = 'smartTV',
  summary = 'summary',
  teaser = 'teaser',
  textHTML = 'textHTML',
  timeshift = 'timeshift',
  tvScreen = 'tvScreen',
  ultraHD = 'ultraHD',
  video = 'video',
  voiceControlBanner = 'voiceControlBanner',
  voiceInfo = 'voiceInfo',
  mobile = 'mobile',
}

/** Типы заявок/обращений */
export enum WORKFLOW_IDS {
  // 'ОКП: связаться с клиентом'
  CONTACT_THE_CUSTOMER = 'ClientPhoneServiceContactClient',
  // 'Пользователь: неисправность'
  MALFUNCTION = 'TechnicalSupportClientRequest',
  // 'ОВП B2C: Новая продажа'
  NEW_SALE = 'InboundSalesB2CNewSale',
  // 'ОКП: Смена технологии подключения'
  CHANGE_CONNECTION_TYPE = 'ClientPhoneServiceDataNetworkSwitch',
}

/** Причины обращений */
export enum DEMAND_CAUSE {
  // 'ОКП: Консультация по доп.услугам'
  ADDITIONAL_SERVICES = 'ClientPhoneServiceConsultationToAdditionalServices',
}

/** Значение телефона по умолчанию */
export const INPUT_PHONE_VALUE = { value: '', forSend: '' };

/** Начальный результат сайдпейджа */
export const INITIAL_RESULT = {
  isResult: false,
  isCorrect: true,
  text: '',
};

/** Тип исключения для ошибки, если договор не найден */
export const CONTRACT_NOT_FOUND_TYPE = 'ContractNotFoundException';

/** Коды продуктов */
export const SERIES_CODE = {
  HIT_SUPER_2_0: 'hit.super.2.0',
  ONLINE_SUPER_2_0: 'online.super.2.0',
  HIT_SUPER: 'hit.super',
  ONLINE_SUPER: 'online.super',
  HIT_180: 'hit.180',
  ONLINE_180: 'online.180',
  /** Закрытый договор ЧК */
  CLOSE_CHK: 'closed.private',
  EMPLOYEE: 'special',
};

/** Типы исключений для метода Demand/CreateDemand */
export enum DEMAND_REQUEST_ERRORS {
  SWITCH_CONNECTION = 'DemandDataNetworkSwitchOverLimitException',
  TOO_MUCH_REQUESTS = 'DemandCountOverLimitException',
}

/** Костыль для истории смены продукта
 *  Дата начала обслуживания может быть позже даты смены продукта
 *  из-за чего в метод уходит неверный интервал
 *  TODO: удалить после рефакторинга метода GetTariffHistory
 */
export const DATE_FROM = format(new Date(2000, 1, 1), 'yyyy-MM-dd');

/** state result по умолчанию */
export const DEFAULT_RESULT = {
  isResult: false,
  isCorrect: true,
  text: '',
};

/** Код игровой маркетинг категории */
export const GAME = 'game';
